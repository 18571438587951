.contact-modal {
    $contact-modal-x-padding: $default-padding * 8;
    $contact-modal-y-padding: $default-padding * 2;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    box-shadow: 0 0 3px 5px #fff, 0 0 30px 15px #0095ff !important;
    overscroll-behavior: none;

    @include media-breakpoint-down(xs) {
        box-shadow: none !important;
    }

    line-numbers-component {
        flex: 0 1 auto;
        height: 100vh !important;
    }

    .contact-modal-canvas {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        padding: $contact-modal-y-padding * 2 $contact-modal-x-padding;
        height: 100vh;
    }

    .contact-modal-header {
        background-color: #000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $default-margin * 2;
        padding: $default-padding;

        h4 {
            margin: 0;
        }
    }

    .contact-modal-body {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }

    .whole-modal {
        margin-left: -$contact-modal-x-padding;
        margin-right: -$contact-modal-x-padding;

        padding: $default-padding * 2 $contact-modal-x-padding;
    }

    .result-info {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .img-canvas {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                padding: $default-padding * 2 $default-padding * 4;
                background-color: #fff;
                box-shadow: 0 0 black;
            }
        }

        .company-info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .result-title {
                text-transform: uppercase;
                font-size: $font-size-base * 1.1;
            }

            .result-region,
            .result-company-name,
            .result-due-to {
                color: $black-shade-5;
            }
        }
    }

    .email-and-name-canvas {
        display: flex;
        flex-wrap: wrap;
        #contact-email-field, #contact-name-field {
            width: 300px;
            max-width: 300px;
            padding: 0 $default-padding * 6;
            margin-right: 5px;
            border-radius: 5px;

            + .sta-validation-error {
                max-width: 300px;
            }
        }
    }

    textarea {
        width: 100%;
        height: 100%;
        padding: $default-padding * 6;
        border-radius: 5px;
    }

    #contact-modal-textarea-loading {
        width: 100%;
        height: 290px; // refers to "rows=10" of textarea element
        border: 2px dashed $black-shade-4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .sta-loading-icon-canvas {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: row;

            @include media-breakpoint-down(xs) {
                max-width: 210px;
                margin: 0 auto;
            }

            .sta-loading-icon {
                @include loading-icon;
                margin-right: $default-margin;

                @include media-breakpoint-down(xs) {
                    font-size: $font-size-sm;
                }
            }
        }

        .user-abort-btn {
            color: $jelly-cherry-jazzy;
            font-size: $font-size-sm;
            opacity: 0;
            transition: opacity 0.2s;
            pointer-events: none;

            @include media-breakpoint-down(xs) {
                font-size: $font-size-sm * 0.8;
            }

            &.sta-visible {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    form {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 30px;
        padding-bottom: $default-padding * 4;

        .input-canvas {
            &.textarea-canvas {
                flex: 1 1 100%;
                margin-bottom: $default-margin;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
            }

            &:not(:first-child) {
                margin-top: $default-margin * 2;
            }
        }

        input.ng-invalid.ng-touched {
            box-shadow: 0 2px 0 0 $jelly-cherry;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: -10px;
                color: $jelly-cherry;
            }
        }
    }

    .file-upload-canvas {
        .file-upload-button-canvas {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;

            .sta-validation-error {
                margin-left: $default-margin;
            }

            @include media-breakpoint-down(xs) {
                flex-direction: column;

                .sta-validation-error {
                    margin-left: 0;
                }
            }
        }

        .files {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
        }
    }

    .applied-already-hint {
        text-align: right;
        color: $orange-olafson;
        @include media-breakpoint-down(xs) {
            text-align: left;
        }
    }

    .job-mailer-hint {
        flex: 1 1 100%;

        label {
            display: none;
        }
    }

    .action-bar {
        position: relative;
        background-color: #000;
        min-height: 60px;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;

        .send-application {
            position: relative;
            text-align: right;
            flex: 0 0 auto;
            display: flex;
            align-items: center;

            img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                height: 50px;
                opacity: 0;
                z-index: -2;
                filter: grayscale(100%) brightness(59%) sepia(1) hue-rotate(65deg) saturate(900%);
            }

            @keyframes move-send-btn {
                from {
                    right: 0;
                }

                to {
                    right: -200%;
                }
            }
        }

        &.sta-sent .send-application {
            overflow: hidden;

            #send-application-btn {
                animation: move-send-btn 0.4s ease-in-out forwards;
            }

            img {
                opacity: 1;
                transition: opacity 0.2s linear 0.2s;
                z-index: unset;
            }
        }

        &.sta-mailer .job-mailer-hint label {
            display: block;
        }
    }

    #send-application-btn {
        @include enter-button;

        &.sta-decent {
            filter: saturate(0.3);
        }
    }

    #close-contact-modal-btn {
        @include delete-button;
    }

    @include media-breakpoint-down(md) {
        .action-bar {
            flex-wrap: wrap;
        }

        .send-application {
            flex: 1 1 100% !important;
            justify-content: flex-end;
        }
    }

    @include media-breakpoint-down(xs) {
        .result-info {
            flex-wrap: wrap;
            flex-direction: column-reverse;
        }

        form {
            margin-top: $default-margin;
        }

        .whole-modal {
            padding: $default-padding * 2 $default-padding;
        }

        .action-bar {
            padding: 1em 0;
            flex-wrap: wrap;
            font-size: $font-size-sm;
        }

        .job-mailer-hint {
            flex: 1 1 100%;
        }

        .send-application {
            justify-content: center;
        }
    }
    .data-privacy-information {
        padding: $default-margin 0;
        font-size: 0.9em;
    }
}
