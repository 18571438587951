
.headline-img {
    @keyframes dat-boi-chucklin {
        0%, 100% {
            transform: translateY(0);
        }

        33% {
            transform: translateY(2px);
        }

        66% {
            transform: translateY(-2px);
        }
    }

    &.sta-happy {
        animation-name: dat-boi-chucklin;
        animation-duration: 0.2s;
        animation-iteration-count: 4;
        animation-timing-function: linear;

    }


    @keyframes heart-spawn {
        0% {
            opacity: 1;
            transform: translateX(10px);
            top: 0;
        }

        30% {
            transform: translateX(-10px);
        }

        60% {
            opacity: 1;
            transform: translateX(10px);
        }

        90% {
            top: -100px;
        }

        100% {
            opacity: 0;
            top: -100px;
        }
    }

    // Hearts are spawned outside the box, due to overflows
    // of panels and the like. Dont wanna mess with that
    @at-root img.sta-tamagotchi-heart {
        position: absolute;
        animation-name: heart-spawn;
        animation-duration: 1s;
        opacity: 0;
        z-index: 10;
        pointer-events: none;
        width: 30px;
        height: auto;
        contain: content;
    }
}
