
achievements-list-component {
    .result {
        min-height: $line-height-result-list * 4;
    }

    .result-body {
        position: relative;
    }

    .achievement-star {
        position: absolute;
        top: 10px;

        img {
            width: 80px;
            height: 80px;
            image-rendering: crisp-edges;
            image-rendering: pixelated;
        }
    }

    .company-name {
        min-height: $line-height-result-list * 2;
    }

    .result .informations {
        padding-left: 6.5rem;
    }
}

@keyframes hover {
    0%, 100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }
}


$achivement-icon-detail-size: 360px;
$achivement-icon-detail-xs-size: 260px;

achievement-detail-component {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    .achievement-solution {
        margin-bottom: $default-margin * 4;
        color: $greenscii;
        text-align: center;
    }

    img {
        width: $achivement-icon-detail-size;
        height: $achivement-icon-detail-size;
        image-rendering: crisp-edges;
        image-rendering: pixelated;
        animation-name: hover;
        animation-duration: 2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        filter: drop-shadow(2px 4px 6px #000);
    }

    @include media-breakpoint-down(xs) {
        // on XS, the thunder lies behind the collapsed matching box
        padding-top: 110px;

        img {
            width: $achivement-icon-detail-xs-size;
            height: $achivement-icon-detail-xs-size;
        }
    }

    @include on_one_column_layout {
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.85);
    }
}

body[sta-state="achievementDetail"] #details-col {
    z-index: 21;
}
