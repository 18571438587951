city-landing-content-component {
    #detail-header {
        text-align: left;
        height: 100px;
        background-image: url(/static/rendering/results/city_shapes/skyline.svg);
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: $default-margin * 2;

        @include media-breakpoint-down(sm) {
            height: 50px;
        }
    }

    #details-scroll {
        @include reset_simple_html();

        & > h1 {
            margin-bottom: $default-margin * 2;
        }
    }
    .city-image {
        max-width: 100%;
    }
}

body[sta-state="cityLandingContent"] {
    @extend %mobile-detail-view-on-body;

    @include on_one_column_layout {

        #details-portal {
            height: auto;
        }

        city-landing-content-component {
            height: auto;
        }

        #thunder {
            position: relative;
        }

        #details-scroll {
            overflow-y: auto !important;
        }
    }
}
