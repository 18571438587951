@keyframes selected-glow {
    0% {
        background-color: #000;
        color: #000;
    }
    5% {
        background-color: #fff;
        color: #fff;
    }
    10% {
        background-color: $greenscii;
        color: $greenscii;
    }
    50% {
        background-color: inherit;
        color: inherit;
    }
}
