/////
//
//  Just a couple of big-screen alignments, Simon asked for :)
//
/////
@media (min-width: 1200px) {
    #details-col {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media (min-width: 1400px) {
    #details-col {
        padding-left: 60px;
        padding-right: 80px;
    }
}

@media (min-width: 1600px) {
    #details-col {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media (min-width: 1700px) {
    #details-col {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media (min-width: 1800px) {
    #details-col {
        padding-left: 20px;
        padding-right: 80px;
    }
}
