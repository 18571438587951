work-experience-component {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;

        // every flex-child uses full width
        > * {
            flex: 1 1 100%;
        }
    }

    #work-exp-label {
        text-transform: uppercase;
        padding-left: $default-padding * 2;

        @include media-breakpoint-down(sm) {
            margin-bottom: $default-margin;
        }
    }

    #work-exp-step-labels {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include media-breakpoint-down(sm) {
            justify-content: space-between;
        }

        &[sta-lvl="0"] {
            button:nth-child(1) {
                @include active-check-button;
            }
        }

        &[sta-lvl="1"] {
            button:nth-child(2) {
                @include active-check-button;
            }
        }

        &[sta-lvl="2"] {
            button:nth-child(3) {
                @include active-check-button;
            }
        }
    }
}
