$results-list-min-height: 70vh;
$result-padding-left: $default-padding * 5;
$line-numbers-min-height: $results-list-min-height;
$line-numbers-line-height: $line-height-result-list;
@import "lib/Helper/line-numbers";
@import "results-page-divider-component";
@import "user-matching-weights-component";

@mixin load-more-canvas-size {
    height: $line-height-result-list;
    margin-bottom: $line-height-result-list * 4;
    margin-top: $line-height-result-list * 2;
}

@mixin salary-border($color, $diameter: 40px) {
    background: linear-gradient(135deg,
            transparent calc(100% - #{$diameter}),
            $color 97%);

    // Radial would fulfil the design better, but it is more difficult to position.
    //background: radial-gradient(500px 430px at 30% -118%, transparent 80%, transparent 90%, $color 100%);
}

@keyframes build-list {
    0% {
        opacity: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        height: 100%;
    }
}


results-list-component,
achievements-list-component {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    line-height: $line-height-result-list;

    margin-top: $default-margin * 5.5;
    max-height: 50vh;
    overflow-y: auto;

    // changed after animation
    height: 0;
    opacity: 0;

    animation-duration: 0.1s;
    animation-delay: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    overflow-x: hidden;
    overscroll-behavior-y: none;

    $fade-height: 60px;

    $upper-gradient: linear-gradient(180deg,
            transparent,
            black $fade-height,
            black calc(100% - #{$fade-height}),
            transparent 100%
    );
    -webkit-mask-image: $upper-gradient;
    mask-image: $upper-gradient;

    &.sta-built {
        animation-name: build-list;

        @at-root body.sta-immediate-results & {
            animation-duration: 0s;
            animation-delay: 0s;
        }
    }

    @at-root body:not(.sta-params-collapsed) &.sta-built {
        display: none;
    }

    @at-root body.sta-params-collapsed & {
        max-height: $results-list-min-height;
        min-height: $results-list-min-height;

        @include on_safari {
            max-height: $results-list-min-height - 6vh;
            min-height: $results-list-min-height - 6vh;
        }
    }

    @include media-breakpoint-down(xs) {
        // on XS we dont need the "flex" features for <line-numbers-comp> overflowing,
        // because we're not using it
        display: block;
        margin-top: $default-margin * 3.5;
        animation-delay: 0s;
        -webkit-mask-image: none;
        mask-image: none;
        line-height: $line-height-result-list-xs;

    }

    // When we dont go on a two-column layout, there's no reason to limit
    // the result-list to display a scrollbar.
    @include on_one_column_layout {
        max-height: unset !important;
        overscroll-behavior-y: initial;

        &.sta-built {
            // minHeight, when list is not visible, makes no sense.
            min-height: 60vh !important;
        }
    }

    @include purple-scroll;

    #horizontal-divider {
        height: $line-height-result-list;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        #actual-horizontal-divider {
            height: 2px;
            width: calc(100% + #{$default-padding * 4} + #{$default-padding * 6});
            background-color: $deep-purple;
            margin-left: $default-padding * -4;
            margin-right: $default-padding * -6;
        }
    }

    .matching-headline {
        margin: $line-height-result-list 0 0;

        span {
            font-weight: $font-weight-bold;
        }
    }

    .result-list {
        flex: 1 1 auto;
        padding-left: $default-padding * 4;
        padding-right: $default-padding * 4;
        position: relative;

        @include media-breakpoint-down(xs) {
            padding-top: 40px;
        }

        @include on_one_column_layout {
            // If the detail view is opened and screen-filling, we should hide the result-list.
            // Else it may shimmer through the borders of a collapsed params box.
            @at-root body.sta-details-open & {
                /*opacity: 0;
                transition: opacity 0.2s;*/
            }
        }
    }

    // make rules available for detailview card preview usage
    @at-root .result-card, .result {
        display: block;
        color: inherit;
        padding-left: $result-padding-left;
        position: relative;
        border-radius: 5px;

        // We eventually move <inline-detail-view> parts between result blocks,
        // and using "+" operator would break the margin-top of the next result.
        // Thats why "~" is preferred
        ~ .result {
            margin-top: $line-height-result-list;
        }

        &:hover {
            cursor: pointer;
            text-decoration: none;

            .result-headline {
                text-decoration: underline;
            }
        }

        &.seen {
            .result-headline {
                color: $bright-purple;

                &:before {
                    color: $bright-purple;
                }
            }
        }

        &.sta-open {
            background-color: $dark-purple;

            .result-headline {
                color: #fff;

                &:before {
                    display: none !important;
                }
            }

            .result-salary-hint {
                @include salary-border($greenscii);
            }
        }

        .result-headline {
            color: #fff;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            position: relative;

            &:before {
                content: ">";
                position: absolute;
                top: 0;
                left: -20px;
                color: $greenscii;

            }
        }

        .informations {
            padding-left: $default-padding * 14;
        }

        .company-name {
            color: $black-shade-6;
        }

        .region {
            color: darken($greenscii, 23%);

            .no-region {
                color: $black-shade-4;
            }
        }

        .skills {
            color: $black-shade-6;

            .job-tag {
                display: inline-block;
                margin-right: $default-margin;
                word-break: break-word;

                &.match {
                    color: $greenscii;
                }

                &.no-matching-skills {
                    color: $black-shade-4;
                }
            }
        }

        .skills .tags span {
            display: inline-block;
        }

        .result-salary-hint {
            $diameter: 40px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            @include salary-border($dark-purple);
        }

        + results-loading-component {
            // When displayed below an existing result, we want more space
            // to top/bottom than if no results are there and the loading-component
            // is the only thing visible on the result-list.
            @include load-more-canvas-size;
        }
    }

    .load-more-canvas {
        text-align: center;
        @include load-more-canvas-size;

        button {
            height: $line-height-result-list;
            line-height: 0;
            border: none;
            box-shadow: none;
            background-color: transparent;
            text-transform: uppercase;
            letter-spacing: 2px;
            min-width: 300px;

            span {
                color: $greenscii;

                &:first-child {
                    margin-right: $default-margin;
                }

                &:last-child {
                    margin-left: $default-margin;
                }
            }

            &:hover {
                text-decoration: underline;
            }

        }

        .better-matches-text {
            font-size: 0.9em;
        }
    }

    #scroll-top-results {
        position: absolute;
        bottom: $line-height-result-list * 3;
        height: $line-height-result-list;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size-lg;

        &:hover {
            text-decoration: underline;
        }
    }
}
