switch-language-footer-component {
    select {
        color: inherit;
        outline: 0;
        border: none;
        background-color: transparent;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
