@keyframes bug-movement {
    0% {
        transform: translate(-100px, 0);
    }

    10% {
        transform: translate(-200px, 0);
    }

    19% {
        transform: translate(-200px, 0);
    }

    20% {
        transform: translate(-200px, -20px);
    }

    30% {
        transform: translate(-500px, -20px);
    }

    50%, 60% {
        transform: translate(-500px, -20px);
    }

    68% {
        transform: translate(-500px, -20px) rotateY(0deg);
    }

    70% {
        transform: translate(-500px, -20px) rotateY(-180deg);
    }


    100% {
        transform: translate(0, 0) rotateY(-180deg);
    }
}

@keyframes bug-sprite {
    0% {
        background-position: -10px -10px;
    }

    25% {
        background-position: -110px -10px;
    }

    50% {
        background-position: -210px -10px;
    }

    75% {
        background-position: -10px -116px;
    }
}

@keyframes bug-flatsch-sprite {
    0% {
        background-position: -10px -10px;
    }

    25% {
        background-position: -110px -10px;
    }


    50% {
        background-position: -210px -10px;
    }


    75% {
        background-position: -10px -111px;
    }

    99% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

#bug {
    position: fixed;
    bottom: 0;
    right: -100px;
    width: 80px;
    height: 86px;
    z-index: 100;
    cursor: pointer;
    background-image: url("/static/rendering/results/bug_sprite.png");
    display: none;

    &.sta-show {
        display: block;
        animation: bug-movement 5s linear forwards, bug-sprite 0.2s steps(1) infinite;
    }

    &.sta-flatsch {
        animation-play-state: paused;
        background-image: none;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 80px;
            height: 81px;
            background-image: url("/static/rendering/results/bug_flatsch_anim.png");
            animation: bug-flatsch-sprite 0.25s steps(1) forwards;
        }

        #bug-shadow {
            display: none;
        }
    }

    #bug-shadow {
        box-shadow: 0 0 10px 5px transparentize($black-shade-0, 0.5);
        background-color: transparentize($black-shade-0, 0.5);
        border-radius: 40%;
        height: 5px;
        width: 80px;
        margin-top: 86px - 5px;
    }
}
