.tag {
    $tag-border: hsl(120, 100%, 24%);
    $tag-hover-bg: darken($tag-border, 10%);


    border: 1px solid $tag-border;
    padding: $default-padding $default-padding * 2;
    color: $greenscii;
    display: inline-block;
    margin-right: $default-margin / 4;
    margin-bottom: $default-margin / 4;
    box-shadow: 1px 1px 7px $black-shade-0;
    position: relative;
    transition: all 0.1s;
    word-break: break-word;
    border-radius: 5px;
    font-size: $font-size-sm;

    .remove-tag {
        color: $jelly-cherry-jazzy;
    }

    // Only on devices, that support hover, we use the "hide delete-icon until required" feature.
    // Touch devices will always see the delete icon, as they have no (clear) way to hover
    @media (hover: hover) {
        &:not(.sta-no-hover):hover {
            background-color: $tag-hover-bg;
            color: $tag-hover-bg;
            cursor: pointer;

            .remove-tag {
                opacity: 1;
            }
        }

        .remove-tag {
            opacity: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: opacity 0.1s;
        }
    }
}
