@keyframes baum-glitch {
    0% {
        filter: url(#glitch);
        transform: scale(1);
    }

    35% {
        transform: scale(1);
    }

    40% {
        transform: scale(0);
        filter: url(#glitch);
    }

    51% {
        filter: none;
        box-shadow: 0 40px 100px 200px red;
    }
    80%, 100% {
        transform: scale(0);
    }
}

#matching-machine {
    &.sta-glitch {
        animation-name: baum-glitch !important;
        animation-duration: 5s;
        animation-fill-mode: unset !important;
        animation-timing-function: linear;
    }
}

.sta-boot-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    z-index: 3;
    @extend %screen-bg-radial;
    font-size: $font-size-smaller;
}

@keyframes waggle {
    from {
        transform: translateX(2px);
    }
    to {
        transform: translateX(-2px);
    }
}

.sta-waggle {
    animation: waggle 0.3s infinite steps(2) alternate;
    display: inline-block; // explicitly required for transform to work
}
