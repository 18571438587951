// purgecss comments need to appear on a file level. they purge the svg targeting rules, as the svg is not present at buildtime
/*! purgecss start ignore */
job-title-content-component {
    #job-title-canvas {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;

        h1 {
            position: absolute;
            top: 10%;
            font-size: $h3-font-size;
            font-weight: $font-weight-bold;
            color: $greenscii;
            text-transform: uppercase;
            filter: drop-shadow(0 0 4px #000000);
            border: 2px solid $dark-greenscii;
            padding: $default-padding * 3 $default-padding * 10;
            background-color: $black-shade-0;
        }

        svg.radar-img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 120%;
            max-width: 60vw; // overlaps footer area otherwise
            height: 120%;
            max-height: 100vh;
            opacity: 0.5;
        }

        #shape-canvas {
            position: absolute;
            max-width: 600px;
            width: 100%;
            height: 460px; // rather hacky to use a fixed value here
            display: flex;
            justify-content: center;
            align-items: center;
        }

        #pseudo-shape {
            position: relative;
        }

        @keyframes tooltip-flicker {
            0% {
                opacity: 1;
            }

            3% {
                opacity: 0;
            }

            6% {
                opacity: 1;
            }

            8% {
                opacity: 0;
            }

            15% {
                opacity: 1;
            }

            50% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                opacity: 1;
            }
        }

        .marker {
            position: absolute;
            // "top" and "left" are set by ng-style. "translate" centers properly, including elements own size
            transform: translateY(8px) translateX(-9px);
            z-index: 1;

            @keyframes marker-glow {
                0% {
                    fill: hsl(120, 100%, 47%);
                }
                4% {
                    fill: hsl(120, 100%, 50%);
                }
                5% {
                    fill: hsl(120, 100%, 47%);
                }
                10%, 100% {
                    fill: $dark-greenscii;
                }

            }

            .marker-icon {
                &:hover {
                    z-index: 7;

                    svg {
                        z-index: 7;
                        // !important due marker-glow animation
                        fill: $greenscii !important;
                    }

                    + .tooltip {
                        display: block;
                        animation-name: tooltip-flicker;
                        animation-duration: 2s;
                        animation-fill-mode: forwards;
                        animation-timing-function: steps(20);
                        z-index: 7;
                    }
                }

                svg {
                    fill: $dark-greenscii;
                    stroke: none;
                    width: 15px;
                    height: 15px;
                    animation-name: marker-glow;
                    animation-duration: 10s;
                    animation-direction: normal;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                    animation-delay: 0.5s;

                    filter: drop-shadow(0 0 5px $black-shade-1);
                    z-index: 5;
                }
            }

            .tooltip {
                position: absolute;
                display: none;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%) translateY(-20px);
                color: $black-shade-0;

                z-index: 6;
                opacity: 0.7;
                min-width: 200px;

                %tooltip-item {
                    background-color: transparentize($dark-greenscii, 0.05);
                    padding: $default-padding $default-padding * 2;
                    border: 1px solid $black-shade-0;
                }

                .job-title {
                    @extend %tooltip-item;
                    margin-bottom: $default-padding;
                }

                .job-company {
                    @extend %tooltip-item;
                    font-size: $font-size-smaller;
                }
            }

            // need to raise the container aswell, so following child elements can be overlapped by this
            &:hover {
                z-index: 7;
            }
        }

        @keyframes glitch {
            0% {
                filter: url(#glitch);
                opacity: 1;
            }
            2% {
                filter: none;
                opacity: 0;
            }
            4% {
                filter: url(#glitch);
                opacity: 1;
            }
            8% {
                filter: none;
            }
            10% {
                filter: url(#glitch);
                opacity: 1;
            }
            23% {
                opacity: 1;
            }
            24% {
                filter: none;
                opacity: 1;
            }
        }

        #city-shape {
            position: absolute;
            animation-duration: 3.2s;
            animation-iteration-count: infinite;
            max-width: 600px;
            width: 100%;
            fill: none !important;
            height: 500px;

            &.sta-glitch {
                animation-name: glitch;
            }

            #axes_1 path {
                fill: rgba(0, 10, 0, 0.32) !important;
                stroke: $dark-greenscii !important;
                filter: url(#bordershadow);
            }
        }
    }
}

@keyframes blink {
    0%, 6%, 12% {
        opacity: 0;
    }

    3%, 9%, 15%, to {
        opacity: 1;
    }
}

[name="detailView"] {
    &.ng-enter, &.ng-leave #job-title-canvas {
        transition: all 1s linear;
    }

    &.ng-enter #job-title-canvas {
        animation: blink 1s linear;
    }
}

/*! purgecss end ignore */

body[sta-state="jobTitleLanding"] {
    @extend %mobile-detail-view-on-body;

    @include media-breakpoint-down(sm) {
        #details-col {
            margin-top: -40px;
        }
        #job-title-canvas {
            overflow-x: hidden;

            #city-shape, #pseudo-shape {
                height: 400px;
                margin-top: 100px;
            }
        }
    }
}
