/*
  TODO this is a nearly exact clone of contact-modal. should made reusable
 */
.job-mailer-modal {
  $contact-modal-x-padding: $default-padding * 8;
  $contact-modal-y-padding: $default-padding * 2;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  box-shadow: 0 0 3px 5px #fff, 0 0 30px 15px #0095ff !important;
  overscroll-behavior: none;

  @include media-breakpoint-down(xs) {
    box-shadow: none !important;
  }

  line-numbers-component {
    flex: 0 1 auto;
    height: 100vh !important;
  }

  .job-mailer-modal-canvas {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: $contact-modal-y-padding * 2 $contact-modal-x-padding;
    height: 100vh;
  }


  .job-mailer-modal-headline {
    background-color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $default-margin * 2;
    padding: $default-padding;

    h4 {
      margin: 0;
    }
  }

  .whole-modal {
    margin-left: -$contact-modal-x-padding;
    margin-right: -$contact-modal-x-padding;

    padding: $default-padding * 2 $contact-modal-x-padding;
  }

  .modal-body {
    flex: 1 1 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 6vh;

    .profile {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      row-gap: $default-margin;

    }

    .profile-section {
      position: relative;
      padding-left: 20px;

      &:before {
        content: ">";
        display: block;
        position: absolute;
        top: 4px;
        left: 0;
        color: $greenscii;
      }
    }

    input[type="email"] {
      width: 100%;
      max-width: 300px;
      padding: 0 1.5rem;
      border-radius: 5px;

      + .sta-validation-error {
        max-width: 300px;
      }
    }

    blockquote {
      background-color: $black-shade-2;
      padding: $default-padding * 3 $default-padding * 6;
      position: relative;
      margin: 0;

      &:before {
        content: "";
        position: absolute;
        top: 5px;
        bottom: 5px;
        left: 5px;
        width: 10px;
        background-color: $black-shade-1;
      }

      &.warning {
        color: $orange-olafson;
      }
    }
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;

    background-color: #000;
    min-height: 60px;
  }

  #close-job-mailer-modal-btn {
    @include delete-button;
  }

  #save-job-mailer-btn {
    @include enter-button;
  }

  @include media-breakpoint-down(xs) {
    .whole-modal {
      padding: $default-padding * 2 $default-padding;
    }
  }
}
