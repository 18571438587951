user-matching-weight-component {
    $label-weight: $greenscii;
    $label-no-weight: $black-shade-4;
    $thumb-height: 30px;
    $thumb-width: 16px;
    $thumb-color: $purple-rain;
    $bar-height: $thumb-height * 0.8;
    $bar-width: 10px;

    .user-matching-weights {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;

        .input-canvas {
            flex-basis: 60%;
            max-width: 300px;
        }

        input {
            width: 100%;
            appearance: none;
            position: relative;
            z-index: 1;
            background-color: transparent;

            &::-webkit-slider-thumb {
                appearance: none;
                height: $thumb-height;
                width: $thumb-width;
                background-color: $thumb-color;
                border: 3px solid $bright-purple;
                border-bottom-color: $purple-blue;
                border-right-color: $purple-blue;
                cursor: pointer;
                z-index: 5;
            }

            &::-webkit-slider-runnable-track {
                height: $thumb-height;
                background-color: transparent;
                //border: 1px dotted red;
            }
        }

        .weight-label {
            cursor: pointer;
        }

        .weight-bars {
            height: $thumb-height + 4;
            display: flex;
            justify-content: space-evenly;
            margin-bottom: -34px; // TODO formalize
            padding: 2px $thumb-width;
            padding-bottom: 0;
            // border: 1px solid $black-shade-4;
            //margin-left: 5px;

            .weight-bar {
                position: relative;
                width: $bar-width;
                transition: 0.2s height, 0.2s top;

                /* [START] Colorize */
                @for $i from 1 through 9 {
                    &:nth-child(#{$i}) {
                        @if $i <= 3 {
                            background-color: $black-shade-6;
                        } @else if ($i <= 6) {
                            background-color: $black-shade-5;
                        } @else {
                            background-color: darken($black-shade-4, 10);
                        }
                    }
                }

                @for $i from 10 through 18 {
                    &:nth-child(#{$i}) {
                        @if $i < 13 {
                            background-color: darken($black-shade-4, 10);
                        } @else if ($i < 16) {
                            background-color: $black-shade-5;
                        } @else {
                            background-color: $black-shade-6;
                        }
                    }
                }
                /* [END] Colorize */

                /* [START] Heights */
                &:nth-child(1) {
                    $part: 3;
                    height: $bar-height / $part;
                    top: $bar-height - ($bar-height / $part);
                }

                &:nth-child(2) {
                    $part: 4;
                    height: $bar-height / $part;
                    top: $bar-height - ($bar-height / $part);
                }

                @for $i from 3 through 16 {
                    &:nth-child(#{$i}) {
                        $part: 5;
                        height: $bar-height / $part;
                        top: $bar-height - ($bar-height / $part);
                    }
                }

                &:nth-child(17) {
                    $part: 4;
                    height: $bar-height / $part;
                    top: $bar-height - ($bar-height / $part);
                }

                &:nth-child(18) {
                    $part: 3;
                    height: $bar-height / $part;
                    top: $bar-height - ($bar-height / $part);
                }

                /* [END] Heights */
            }
        }

        $most-active: darken($greenscii, 20%);
        $medium-active: darken($greenscii, 27.5%);
        $least-active: darken($greenscii, 35%);

        &[sta-weight="0"] {
            .weight-2 {
                color: $label-no-weight;
            }

            .weight-0 {
                color: $label-weight;
            }

            .weight-bar {
                @for $i from 1 through 9 {
                    &:nth-child(#{$i}) {
                        @if $i <= 3 {
                            background-color: $most-active;
                        } @else if ($i <= 6) {
                            background-color: $medium-active;
                        } @else {
                            background-color: $least-active;
                        }
                    }
                }

                &:nth-child(1) {
                    $part: 0.9;
                    height: $bar-height * 0.9;
                    top: $bar-height - ($bar-height * $part);
                }

                &:nth-child(2) {
                    $part: 0.75;
                    height: $bar-height * $part;
                    top: $bar-height - ($bar-height * $part);
                }

                &:nth-child(3) {
                    $part: 0.6;
                    height: $bar-height * $part;
                    top: $bar-height - ($bar-height * $part);
                }

                &:nth-child(4) {
                    $part: 0.4;
                    height: $bar-height * $part;
                    top: $bar-height - ($bar-height * $part);
                }

                &:nth-child(5) {
                    $part: 0.3;
                    height: $bar-height * $part;
                    top: $bar-height - ($bar-height * $part);
                }
            }
        }

        &[sta-weight="2"] {
            .weight-2 {
                color: $label-weight;
            }

            .weight-0 {
                color: $label-no-weight;
            }

            .weight-bar {
                @for $i from 10 through 18 {
                    &:nth-child(#{$i}) {
                        @if $i < 13 {
                            background-color: $least-active;
                        } @else if ($i < 16) {
                            background-color: $medium-active;
                        } @else {
                            background-color: $most-active;
                        }
                    }
                }

                &:nth-child(18) {
                    $part: 0.9;
                    height: $bar-height * $part;
                    top: $bar-height - ($bar-height * $part);
                }

                &:nth-child(17) {
                    $part: 0.75;
                    height: $bar-height * $part;
                    top: $bar-height - ($bar-height * $part);
                }

                &:nth-child(16) {
                    $part: 0.6;
                    height: $bar-height * $part;
                    top: $bar-height - ($bar-height * $part);
                }

                &:nth-child(15) {
                    $part: 0.4;
                    height: $bar-height * $part;
                    top: $bar-height - ($bar-height * $part);
                }

                &:nth-child(14) {
                    $part: 0.3;
                    height: $bar-height * $part;
                    top: $bar-height - ($bar-height * $part);
                    color: red;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .input-canvas {
                max-width: 220px;
            }
            .weight-bar {
                &:nth-child(3), &:nth-child(6), &:nth-child(9),
                &:nth-child(10), &:nth-child(13), &:nth-child(16) {
                    display: none;
                }
            }
        }
    }
}
