$tron-race1-base-x: 45.5%;
$tron-race1-base-y: 300px;
$tron-race1-delay: 2s;
$tron-duration: 4.8s;

@keyframes tron-v-line {
  0% {
    transform: translateY(400px) translateX($tron-race1-base-x);
  }
  5% {
    transform: translateY($tron-race1-base-y) translateX($tron-race1-base-x);
  }
  //> pause
  18% {
    transform: translateY($tron-race1-base-y) translateX($tron-race1-base-x);
    height: 100%;
  }
  20% {
    transform: translateY($tron-race1-base-y) translateX($tron-race1-base-x);
    height: 0;
  }
  100% {
    transform: translateY($tron-race1-base-y) translateX($tron-race1-base-x);
    height: 0;
  }
}

@keyframes tron-h-line {
  0% {
    opacity: 1;
    width: 0;
    transform: translateX($tron-race1-base-x);
  }
  5% {
    opacity: 1;
    width: 100%;
    transform: translateX($tron-race1-base-x);
  }
  //> pause
  17% {
    opacity: 1;
    width: 100%;

    // base transform
    transform: translateX($tron-race1-base-x);

  }
  20%, 100% {
    opacity: 1;
    width: 100%;
    transform: translateX(100%);
  }
}

.tron-footer-canvas {
  width: 100%;
  height: 400px;
  position: fixed;
  bottom: 0;
  overflow: hidden;
  z-index: -2;


  $perspective: true;
  $animate: true;

  @include media-breakpoint-down(xs) {
    display: none;
  }

  svg {
    position: relative;
    z-index: -2; // Safari 10.0 requires explicit z-index here
    @if $perspective {
      transform: perspective(50px) rotateX(45deg) scale(1, 0.3);
      -webkit-mask-image: -webkit-gradient(linear, left 90%, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    }
  }

  @keyframes troner {
    0% {
      transform: perspective(200px) rotateX(40deg) scale(2, 1) translateX(0);
    }

    100% {
      transform: perspective(200px) rotateX(40deg) scale(2, 1) translateX(1em);
    }
  }

  #tron-racer.sta-show {
    // on safari, the tron-racer blacks half the screen for the remaining session, its a good browser or so
    @include on_safari {
      display: none !important;
    }

    filter: drop-shadow(7px 8px 4px $jelly-cherry-jazzy);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


    #tron-line-h,
    #tron-line-v {
      animation-duration: $tron-duration;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      fill: $jelly-cherry-jazzy;
    }

    #tron-line-v {
      @if $animate {
        transform: translateY(400px) translateX($tron-race1-base-x);
        animation-name: tron-v-line;
        animation-delay: $tron-race1-delay;
      } @else {
        transform: translateY(80px) translateX($tron-race1-base-x);
      }


    }

    #tron-line-h {
      @if $animate {
        opacity: 0;
        transform: translateX($tron-race1-base-x);
        animation-name: tron-h-line;
        animation-delay: $tron-race1-delay + 0.2s;
      } @else {
        transform: translateX($tron-race1-base-x);
        width: 100%;
      }
    }
  }
}
