body {
    // setting a separate color helps diminishing the whitespace on mobile browsers occuring from the address bar moving on scroll
    background-color: $spu-blue;
    background-image: linear-gradient(163deg, #000 0%, #000 34%, $spu-blue 100%);
    background-attachment: fixed;
}

#fallback-bg-img {
    display: none;

    // Safari does not support background-attachment: fixed, so here is a fixed image.. dont ask me why they support this but not that..
    @include on_safari {
        background: none;
        background-color: #000;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -2;
    }
}
