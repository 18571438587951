@mixin reset_simple_html {
    & {
        color: $body-color;
        font-size: $font-size-base * 0.8;

        // Adverts tend to have very long, non-breakable headlines, so we should
        // force breaking.
        word-break: break-word;

        @include media-breakpoint-down(sm) {
            font-size: $font-size-base * 0.85;
        }

        h1 {
            font-size: $h1-font-size * 0.8;
            text-align: left;
            margin: $default-margin 0;
            @include media-breakpoint-down(sm) {
                font-size: $h1-font-size * 0.6;
            }
        }

        h2 {
            font-size: $h2-font-size * 0.8;
            text-align: left;
            margin: $default-margin 0;
            @include media-breakpoint-down(sm) {
                font-size: $h2-font-size * 0.6;
            }
        }

        h3 {
            font-size: $h3-font-size * 0.8;
            text-align: left;
            margin: $default-margin 0;
            @include media-breakpoint-down(sm) {
                font-size: $h3-font-size * 0.6;
            }
        }

        h4 {
            font-size: $h4-font-size * 0.8;
            text-align: left;
            margin: $default-margin 0;
            @include media-breakpoint-down(sm) {
                font-size: $h4-font-size * 0.6;
            }
        }

        h5 {
            font-size: $h5-font-size * 0.8;
            text-align: left;
            margin: $default-margin 0;
            @include media-breakpoint-down(sm) {
                font-size: $h5-font-size * 0.6;
            }
        }

        h6 {
            font-size: $h6-font-size * 0.8;
            text-align: left;
            margin: $default-margin 0;
            @include media-breakpoint-down(sm) {
                font-size: $h6-font-size * 0.6;
            }
        }

        ul, dl, ol {
            padding-left: $default-padding * 8;
            margin-top: $default-margin;
            margin-bottom: $default-margin;
        }
    }
}
