@import "../style/loading-icon";

@include media-breakpoint-down(xs) {
    // The popped out input fields dont work on Safari mobile...

    @supports not (-webkit-touch-callout: none) {
        .autocomplete-input:focus {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 20;
            padding-top: $default-padding * 2;
            padding-bottom: $default-padding * 2;
            border-radius: 40px;
            outline: none;
        }

        .autocomplete {
            height: 80vh !important;
            overscroll-behavior: none;
        }
    }
}

// suggestions menu
.autocomplete {
    background: $black-shade-1;
    z-index: 10;
    overflow: auto;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px 0 $black-shade-0;

    > div {
        padding: 0 $default-padding;
        margin-bottom: 5px;

        .item-cols {
            display: flex;
            justify-content: space-between;
            // for multi-line suggestions, the .job-count shall be aligned at the bottom
            // https://trello.com/c/CVjNhGeI
            align-items: flex-end;
            padding: 0 $default-padding * 2;
        }

        .name {
            word-break: break-word;
            color: #fff;

            // The part of the suggestion that is also part of the query is visually less
            // dominant than the "new" characters in it. Highlight happens based on color, not on
            // font-weight.
            .query {
                color: $black-shade-5;
            }
        }

        .job-count {
            color: $black-shade-5;
        }

        .apply-suggestion {
            color: $black-shade-5;
            transform: rotate(-45deg);
            margin-bottom: 4px;
        }
    }

    .group {
        background: $black-shade-2;
        font-size: $font-size-small;
        padding: $default-padding;
    }

    > div:hover:not(.group),
    > div.selected {
        background: $blue-moon;
        cursor: pointer;
    }

    .loading {
        display: inline-block;
        position: relative;
        padding: $default-padding $default-padding * 2;
        overflow: hidden;

        /*        &:after {
                    content: "...";
                    position: absolute;
                    left: 100%;
                }*/

        @if mixin_exists(loading-icon) {
            @include loading-icon();
        }
    }

    .empty {
        display: block;
        color: $jelly-cherry-jazzy;
        text-align: center;
        padding: $default-padding;
    }
}

.autocomplete-loading-indicator {
    pointer-events: none;
    @include loading-icon;

    &:after {
        display: none;
        color: $black-shade-2;
        position: absolute;
        right: 10px;
        top: -2px;
    }
}

.autocomplete-loading {
    + .autocomplete-loading-indicator:after {
        display: block;
    }
}
