$collapsed-screen-height: 65px;
$collapsed-screen-height-xs: 35px;
$screen-border-radius: 7px;

$screen-glow-transition-duration: 0.4s;
$screen-glow: 0 0 26px -5px #fff;
$screen-glow-active: -3px 0 34px 6px #fff;


$panel-v-padding: $default-padding * 3;
$panel-h-padding: $default-padding * 5;
$inner-panel-color: #6e4bcb;


%screen-bg-radial {
    background-color: $black-shade-2;
    background-image: radial-gradient(
                    transparentize($black-shade-2, 0.75), $black-shade-1 120%
    );
}

@import "decorations";
@import "menu";

ui-view[name="matchingMachine"] {
    display: block;
    width: 100%;
}

.matching-machine {
    display: block;
    margin: 0 auto;
    margin-top: $default-margin * 5;
    width: 360px;
    position: relative;
    z-index: 5;
    border-radius: $screen-border-radius;
    opacity: 0;
    transition: opacity 0.2s ease-out;

    @include media-breakpoint-down(sm) {
        width: 290px;
        margin-top: $default-margin * 2;
    }

    @include media-breakpoint-down(xs) {
        margin-top: $default-margin * 3;
        border-radius: 0;
        box-shadow: 0 0 0 10px $inner-panel-color;
        opacity: 1;

        &.sta-mm-chasis-loaded {
            // TODO ???
            animation-name: none !important;
        }

        @at-root body.sta-params-collapsed & {
            position: fixed;
            top: 30px;
            margin-top: 0;

            // horizontal center
            left: 50%;
            transform: translateX(-50%);

            #matching-machine-screen {
                max-height: $collapsed-screen-height-xs !important;
            }

            // Creates a background for the XS, collapsed box version, so that results are not visible
            // behind the floating box. Its box-shadow makes a nice little fade for results getting scrolled
            // behind the box.
            &:before {
                content: "";
                background-color: #000;
                position: absolute;
                top: -40px;
                bottom: -10px;
                width: 1000px;
                left: -400px;
                z-index: -1;
                box-shadow: 0 1px 15px 30px #000;
            }
        }
    }

    &.sta-mm-chasis-loaded {
        opacity: 1;

        .monitor-panel {
            opacity: 1;
        }
    }

    @at-root body.sta-params-collapsed & {
        #matching-machine-screen {
            max-height: $collapsed-screen-height;
            overflow: hidden;
            transition-duration: 1s;
        }

        @include media-breakpoint-down(xs) {
            #panel-collapse {
                border-bottom: none;
                height: $collapsed-screen-height-xs;
                min-height: $collapsed-screen-height-xs;
            }
        }

        #reflection-top {
            opacity: 0;
            transition: opacity 0.2s ease-in 0.5s;

            @include on_one_column_layout {
                transition: opacity 0s;
            }
        }
    }

    // blank screen at beginning. also it's box-shadow does the inactive screen glow
    .screen-glow-area {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        box-shadow: $screen-glow-active;
        border-radius: $screen-border-radius;
        transition: opacity $screen-glow-transition-duration;
        opacity: 0;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    &:focus-within, &:focus, &:active, &:hover:not(#knebel-hebel) {
        .screen-glow-area {
            opacity: 1;
        }
    }

    .panel {
        flex: 0 1 auto;
        position: relative;
        padding: $panel-v-padding $panel-h-padding;
        width: 100%;

        h5 {
            text-transform: uppercase;
            transition: text-shadow $screen-glow-transition-duration ease-out;
            padding-left: $default-padding * 2;
        }

        input {
            border-radius: 40px;
            padding-left: $default-padding * 3;
            padding-right: $default-padding * 3;
        }

        &.panel-first-item {
            padding-top: $default-padding * 5;
        }
    }

    .panel-head {
        cursor: pointer;
        display: flex;
        padding: 0;
        justify-content: space-between;
        align-items: stretch;
        height: $collapsed-screen-height;
        min-height: $collapsed-screen-height;
        // the fake panel-head for menu grows too big, when machine is artificially made larger for the menu to fit in.
        // If we end up showing all matching-param fields initially (multiple-jobtitles A/B test), this max-height can be removed. Also see xs version
        max-height: $collapsed-screen-height;

        .headline {
            flex: 0 1 85%;
            margin: 0;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 600;

            .headline-img {
                filter: brightness(0.9);
                height: 30px;
                margin-left: $default-margin * 1.5;
                margin-right: $default-margin / 2;
                max-height: 100px;
            }
        }

        .chevron-canvas {
            flex: 1 1 15%;
            border-left: 4px solid $earl-grey;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 1;
            transition: opacity 0.2s;
            color: $greenscii;

            // need to overlay #menu-builder
            position: relative;
            z-index: 2;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 15px;
                height: 15px;
                background-color: $greenscii;
                border-radius: 50%;
                opacity: 0;
                transition: opacity 0.2s;
                border: 2px solid $black-shade-1;
            }

            &.sta-bubble:before {
                opacity: 1;
            }
        }

        @include media-breakpoint-down(xs) {
            height: $collapsed-screen-height-xs + 5px;
            min-height: $collapsed-screen-height-xs + 5px;
            max-height: $collapsed-screen-height-xs + 5px;

            .headline {
                font-size: $font-size-base * 1.1;

                .headline-img {
                    height: 25px;
                    margin: 0 $default-padding;
                }
            }
        }
    }

    // Creates a simulated "border-bottom" of .panel-head, but sticks *above* the #menu-builder,
    // while all the non-menu .panel-head elements go hiding.
    .panel-seperator {
        position: relative;
        z-index: 2; // need to overlay #menu-builder
        background-color: $earl-grey;
        width: 100%;
        // Chapter 8 from my autobiography "You should have really read the flex specs sometime":
        // When collapsing the machine, it's display:flex property causes the .panel-divider and this
        // seperator to shrink below their explicitely defined height. Although it causes a nice collapse
        // transition, it's rather happening by accident.
        // For the seperator though, we really want it to keep the height it has
        min-height: 4px;
    }


    .param-divider {
        background-color: transparent;
        background-image: linear-gradient(to right, hsl(0, 0, 40%) 33%, rgba(255, 255, 255, 0) 0%);
        background-position: center center;
        background-size: 20px 3px;
        background-repeat: repeat-x;
        width: 100%;
        height: 10px;
    }
}

#matching-machine-screen {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    transition: max-height 0.5s ease-in-out;
    max-height: 70vh;
    position: relative;
    z-index: 6;
    @extend %screen-bg-radial;
    border-radius: 5px;


    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        pointer-events: none;
        // TODO does nothing currently, but in the future maybe.
    }

    &.sta-growing {
        overflow: hidden;
    }

    @include media-breakpoint-down(xs) {
        max-height: unset;
    }
}
