.computers-table.sponsoring-active {
    svg {
        width: 60vw;

        .sta-sponsoring-element {
            transition: opacity 1s;
        }

        .sta-sponsoring-element.sta-sponsored {
            opacity: 0;
            border: 2px solid red;
        }

        @at-root body.sta-project-selected & {
            .sta-sponsoring-element {
                &.sta-sponsored {
                    opacity: 1;
                }

                &.sta-not-sponsored {
                    opacity: 0;
                }
            }
        }
    }

    @at-root body:not(.sta-details-open) & {
        z-index: 21;
        pointer-events: all;
    }
}
