@mixin purple-scroll($track-color: #1a094e, $thumb-color: #301193, $scroll-bar-width: 10px) {

    // Firefox
    @supports (scrollbar-color: red) and (scrollbar-width: thin) {
        & {
            scrollbar-color: $thumb-color $track-color;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: $track-color;
    }

    &::-webkit-scrollbar {
        width: $scroll-bar-width;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $thumb-color;
        border-radius: 5px;
    }

    @media (max-width: 666px) {
        // Firefox
        @supports (scrollbar-width: thin) {
            & {
                scrollbar-width: thin;
            }
        }

        &::-webkit-scrollbar {
            width: 3px;
        }
    }
}


@mixin grey-scroll($scroll-bar-width: none) {
    @include purple-scroll(#333, #555, $scroll-bar-width);
}
