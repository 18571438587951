@include on_one_column_layout {
    // With the position:fixed detail overlay, there should be no scroll-events behind that modal.
    // Easiest way should be just disabling scrolling of body element entirely
    body.sta-details-open {
        overflow: hidden;
    }

    #details-col {
        position: fixed;
        z-index: 30; // above matching box
        width: 0; // details-col is not visible by default.

        @include media-breakpoint-down(xs) {
            z-index: 4; // behind matching box
        }

        &.sta-open {
            width: 94vw; // now it became visible
            height: 100vh;
            max-height: 100vh;
            left: 3vw;

            @include media-breakpoint-down(xs) {
                width: 100vw;
                left: 0;
            }
        }
    }

    #thunder {
        @include media-breakpoint-down(xs) {
            // on XS, the thunder lies behind the collapsed matching box
            padding-top: 110px;
        }
    }

    detail-view-component,
    .detail-view-dummy {
        // nest inside detail-view-component, so the rule's specificy equals those of base definitions,
        // and we dont need to pave everything with "!important"

        #details-scroll {
            padding: $default-padding;
            padding-top: 0;
            margin: $default-margin $default-margin * 2;
            overscroll-behavior: unset;

            @media (max-width: 400px) {
                margin: 0 $default-margin / 2;
            }
        }

        #detail-header {
            margin-top: 0;
            padding: $default-padding * 2 $default-padding * 8;

            @media (max-width: 400px) {
                margin: $default-margin / 2;
                padding: 0;
            }
        }

        #detail-header, #details-scroll {
            @media (max-width: 400px) {
                width: 330px;
                max-width: 100vw;
                margin-left: auto;
                margin-right: auto;
            }
        }

        #iframe-canvas-detail-view {
            margin: $default-margin;
        }
    }

    #detail-actions {
        justify-content: space-between;
    }

    #company-logo-canvas {
        margin: $default-margin 0;
        text-align: center;
    }
}
