user-feedback-component {
    display: inline-block;

    a, a:hover {
        color: inherit;
    }
}

.feedback-modal {
    textarea {
        width: 100%;
    }

    label {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }

    #user-feedback-col-2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    button.btn-action {
        @include enter-button;
    }

    .decent {
        color: $black-shade-4;
    }
}
