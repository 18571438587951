@import "../../../../lib/autocompleter/autocomplete.scss";
@import "work-experience";
@import "tag-search-component";

matching-params-component {
    display: block;

    .panel.panel-match {
        padding-bottom: $default-padding * 5;
        padding-top: $default-padding * 5;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include on_safari {
            min-height: 60px;
        }

        #match-button {
            @include enter-button;
            transition: background-color 0.1s, color 0.1s;

            &:not(.sta-active) {

                background-color: $black-shade-2;
                $font-color: $black-shade-4;
                color: $font-color;

                border-color: $black-shade-3;
                border-top-color: $black-shade-5;
                border-left-color: $black-shade-5;

                svg {
                    fill: $font-color;
                }
            }
        }

        #reset-params-button {
            @include delete-button;
        }
    }

    .panel-tags.display-tags-with-searchbar {
        margin-top: $default-margin / 2;
    }

    // For the tag-selected animation blink, we use ng-repeats
    // functionalities to achieve the effect (.ng-enter)
    .panel-tags-single {
        transition: opacity 0.1s;

        &.ng-hide-remove {
            .tag {
                animation: selected-glow 1.2s;
            }
        }

        &.ng-hide-add-active {
            transition: all 0s;
        }
    }

    .tag {
        &.ng-enter, &.ng-enter-active, &.ng-add {
            animation: selected-glow 1.2s;
        }
    }

    plz-search-bar-component {
        display: block;
        text-align: center;
    }

    .job-title-tag {
        @include media-breakpoint-up(md) {
            word-break: unset;
        }
    }
}

