button {
    color: inherit;
    font-weight: $font-weight-bold;
    border-radius: 3px;
    padding: $default-padding * 2 $default-padding * 4;
    outline: 0;
    border: none;
    background-color: $black-shade-2;

    &[disabled] {
        filter: saturate(0);
        cursor: not-allowed;
    }

    &.btn-flat {
        background-color: transparent;
    }

    &.btn-action {
        letter-spacing: 2px;
        text-transform: uppercase;
        background-color: $greenscii;
        color: #000;
    }

    &.btn-link-like {
        background-color: transparent;
        padding: 0;
        font-weight: $font-weight-normal;

        &:hover {
            text-decoration: underline;
        }
    }

    &.btn-check {
        border: 2px solid $black-shade-3;
        border-radius: 5px;
        color: $black-shade-5;
        padding: $default-padding $default-padding * 2;
        margin: 0 $default-margin / 2;
        box-shadow: 1px 1px 7px $black-shade-0;
        font-size: $font-size-sm;

        &.sta-clicked {
            animation: selected-glow 1s;
        }
    }
}

@mixin enter-button() {
    position: relative;
    outline: transparent;
    color: #000;
    font-size: $font-size-lg;
    padding: $default-padding / 2 $default-padding * 2;
    letter-spacing: 2px;
    background-color: $greenscii;

    border: 5px solid $dark-greenscii;
    border-top-color: #caffc2;
    border-left-color: #caffc2;
    box-shadow: none;
    border-radius: 5px;

    transition: box-shadow 0.2s;

    &:not([disabled]) {
        &:hover, &:focus, &:active {
            box-shadow: 0 0 4px 2px lighten($greenscii, 15%);
        }
    }

    @include media-breakpoint-down(xs) {
        font-size: $font-size-sm;
    }
}

@mixin delete-button {
    box-shadow: none;
    padding: 5px 12px;
    color: $jelly-cherry-jazzy;

    border: 5px solid $black-shade-3;
    border-top-color: $black-shade-5;
    border-left-color: $black-shade-5;

    &:hover {
        box-shadow: 0 0 4px 2px lighten($jelly-cherry-jazzy, 15%);
    }

    &:active {
        color: $black-shade-3;
        background-color: $jelly-cherry-jazzy;
    }

    @include media-breakpoint-down(xs) {
        font-size: $font-size-sm;
    }
}

@mixin active-check-button {
    color: $greenscii;
    border: 1px solid $dark-greenscii;
    padding: calc(#{$default-padding} + 1px) calc(#{$default-padding * 2} + 1px);
}
