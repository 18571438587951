$yellow: #ffff00;
$blue: #0000ff;

@keyframes yellow-glow {
    0% {
        filter: drop-shadow(0px 0px 1px $yellow);
        color: darken($yellow, 30%);
    }

    100% {
        filter: drop-shadow(0px -3px 4px $yellow);
        color: yellow;
    }
}

@keyframes blue-glow {
    0% {
        //filter: drop-shadow(0px 0px 1px $blue);
        text-shadow: 0px 0px 1px $blue;
        color: darken($blue, 10%);
    }

    50% {
        color: $blue;
    }

    100% {
        //filter: drop-shadow(0px -2px 4px lighten($blue, 5%));
        text-shadow: 0px -2px 4px lighten($blue, 5%);
        color: lighten($blue, 5%);
    }
}

@keyframes red-glow {
    0% {
        filter: drop-shadow(0px 0px 0px red);
        color: darken(red, 20%);
    }

    50% {

    }

    100% {
        filter: drop-shadow(0px 0px 6px red);
        color: red;
    }
}

@keyframes orange-glow {
    0% {
        //filter: drop-shadow(0px 0px 1px orange);
        text-shadow: 0px 0px 1px orange;
        color: darken(orange, 30%);
    }

    50% {
        color: orange;
    }

    100% {
        //filter: drop-shadow(0px 0px 4px orange);
        text-shadow: 0px -2px 4px orange;
        color: orange;
    }
}

.aoc-banner {
    right: 15vw;
    top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @at-root body:not(.sta-details-open) & {
        z-index: 21;
        pointer-events: all;
    }

    @include media-breakpoint-down(lg) {
        right: 5vw;
    }

    a:hover {
        text-decoration-color: $greenscii;
    }

    #aoc-tree {
        font-weight: bold;
        color: green;
        font-size: 1.4em;

        i {
            font-style: normal;
        }

        .aoc-head {
            color: $yellow;
            //animation: 1s linear infinite alternate yellow-glow;
        }

        .aoc-foot {
            color: grey;
        }

        .aoc-bulb {
            color: blue;
            animation: 0.5s linear 0.5s infinite alternate blue-glow;

        }

        .aoc-star {
            color: yellow;
            animation: 1s linear 0.2s infinite alternate yellow-glow;
            font-size: 18px;
        }

        .aoc-at {
            color: red;
            animation: 0.8s ease-in-out 0.7s infinite alternate red-glow;
        }

        .aoc-small-bulb {
            color: orange;
            animation: 1s linear 0.2s infinite alternate orange-glow;

        }

        .aoc-delayed {
            animation-delay: 0.7s;
        }

        @media (prefers-reduced-motion) {
            .aoc-head, .aoc-bulb, .aoc-star, .aoc-at, .aoc-small-bulb {
                animation: none;
            }
        }
    }

}
