$blue-moon: #0662e7;
$blue-thunder: #0095ff;
$orange-olafson: #ffa500;
$jelly-cherry: #b30053;
$mega-magenta: #eb0072;
$jelly-cherry-jazzy: #ff0077;
$greenscii: #00ff00;
$dark-greenscii: hsl(120, 100%, 24%);

$dark-purple: #331B67;
$dark-spu: #280e72;
$spu-blue: #35119c;
$spu-blue-bright: #3F2495;
$purple-blue: #5432C4;

$deep-purple: #652efc;
$purple-rain: #704FD1;
$violet-velvet: #8566dd;
$bright-purple: #997bea;
$purple-parfume: #c3affb;

$earl-grey: #6c6581;

// Try to use these shades of black for your styles.
// shade-0 is the darkest, shade-4 the brightest.
// shade-0 should be primarily used for box-shadows.
$black-shade-0: #0a0a0a;
$black-shade-1: #151515;
$black-shade-2: #222222;
$black-shade-3: #333333;
$black-shade-4: #646464;
$black-shade-5: #737373;
$black-shade-6: #b3b3b3;


$default-padding: 0.25rem;
$medium-padding: 0.5rem;
$big-padding: 1.0rem;
$large-padding: 1.5rem;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600;

$font-size-small: $font-size-base * .875;
$font-size-smaller: $font-size-base * .8;
$font-size-smallest: $font-size-base * .6;
$font-size-larger: $font-size-lg * 1.3;

$default-margin: 1rem;
$small-margin: 0.5rem;

// by not using the darkest black we have, we still can go darker
// with box-shadows and stand-out from the page
$body-bg: $black-shade-1;
$body-color: #b6b6b6;

$hr-border-color: transparentize($black-shade-1, 0.48);
