/*
Adds additional columns to bootstraps grid system,
if $generate-extra-columns is set to true.
Vanilla stops at xl (1200px), but we want some more.

The following imports are required BEFORE loading this sheet.
@import "bootstrap/functions";
@import "bootstrap/variables";

Curtesy: https://stackoverflow.com/a/48976550
*/

$generate-extra-columns: false !default;

@if $generate-extra-columns {

    /* set the overriding variables */
    $grid-breakpoints: (
            xxxs: 0,
            xxs: 320px,
            xs: 568px,
            sm: 667px,
            md: 768px,
            lg: 992px,
            xl: 1200px,
            xxl: 1440px,
            xxxl: 1600px
    );

    $container-max-widths: (
            xxxs: 0,
            xxs: 320px,
            xs: 568px,
            sm: 667px,
            md: 768px,
            lg: 992px,
            xl: 1200px,
            xxl: 1440px,
            xxxl: 1600px
    );
}
