$sta-coin-dimensions: 160px;
.sta-coin {
    position: absolute;
    top: calc(50% - #{$sta-coin-dimensions / 2});
    left: calc(50% - #{$sta-coin-dimensions / 2});
    width: $sta-coin-dimensions;
    height: $sta-coin-dimensions;
    transform: scale(1);
    transition: top 0.5s, left 0.5s, transform 0.5s;
    user-select: none;
    /* JS will set the final top/left values, where the coin disappears into. */
}

@keyframes smooth-background {
    from {
        background-color: transparentize($black-shade-1, 1);
    }

    to {
        background-color: transparentize($black-shade-1, 0.10);
    }
}

.sta-coin-backdrop {
    animation: smooth-background 0.2s forwards;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
        margin-bottom: $sta-coin-dimensions + 20px;
    }
}
