@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "_bootstrap-extra-columns.scss";

@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities/text";
@import "../../node_modules/bootstrap/scss/tables";

@import "staVariables";

// Optional
// The reboot contains many unsued rules, but I guess in the context of reboots, thats ok.
// Definitely better than copying some parts.
@import "../../node_modules/bootstrap/scss/reboot";
// The only used rules are <h{1,6}> and <hr>. May extract them.
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/utilities";

html {
    // Important for all our rem unit references
    font-size: 16px;
    height: 100%;
}

@font-face {
    font-family: 'Roboto';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
    unicode-range: U+0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: 'Roboto';
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
    unicode-range: U+0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: 'Roboto';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format('woff2');
    unicode-range: U+0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

body {
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.05rem;
    height: 100%;
}

input.wide {
    width: 100%;
}

.clickable {
    cursor: pointer;
}

// used for example on [ngMessages]
.sta-validation-error {
    color: $jelly-cherry-jazzy;

    > [ng-message], > [ng-message-default] {
        margin-top: $default-margin / 2;
        border-left: 5px solid $jelly-cherry;
        border-right: 5px solid $jelly-cherry;
        text-align: center;
    }
}

@mixin on_safari {
    _::-webkit-full-page-media, _:future, :root & {
        @content;
    }
}

@mixin on_safari_13 {
    @media not all and (min-resolution: .001dpcm) {
        @supports (-webkit-appearance:none) and (display:flow-root) {
            & {
                @content;
            }
        }
    }
}

@mixin on_firefox {
    @-moz-document url-prefix() {
        & {
            @content;
        }
    }
}

@mixin on_mobile_safari {
    @supports (-webkit-touch-callout: none) {
        @content;
    }
}

$margins: left, right, top, bottom;
@each $m in $margins {
    .margin-#{$m} {
        margin-#{$m}: $default-margin / 2;
    }
}
