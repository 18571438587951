$neon-text-glow-color: $jelly-cherry-jazzy;
$neon-text-glow-color: $mega-magenta;
$neon-border-color: $blue-moon;

@mixin text-shadow-on($shadow-color: $neon-text-glow-color, $text-color: #fff) {
    color: $text-color;
    // Add the poinkish glow around letters.
    // First line also makes the font a bit more blurry, less sharp-edged
    text-shadow: 0 0 0.1em #fff,
    0 0 0.2em $shadow-color,
    0 0 0.2em $shadow-color,
    0 0 0.2em $shadow-color,
    0 0 0.2em $shadow-color,
    0 0 0.2em $shadow-color;
}

@mixin neon-border-on($brighter-stripes: true) {
    box-shadow: if($brighter-stripes, 0 0 0 2px #fff, 0 0 0 0 #000),
    0 0 0.1em 0 #fff,
    inset 0 0 0.1em 0 #fff, // First two lines add the halogen-bulb stripe,
        // that white-ish bright line, which radiates around the box (thats what the inset is for)
        /* START surround glow */
    0 0 0.5em 0.1em $neon-border-color,
    inset 0 0 0.3em 0.1em $neon-border-color;
}

$header-top-position: 34px !default;
$header-bracket-size: 64px;
$header-bracket-size-full: 80px; // Safari 10.0 goes wild when we animate font-size with em units
$header-intro-delay: 0s;
$subline-delay: 2s;
$subline-duration: 0.5s;

@keyframes header-intro {
    0% {
        top: -100%;
    }

    75% {
        top: $header-top-position * 3;
    }

    100% {
        top: $header-top-position;
    }
}

@keyframes header-subline {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes bracket-grow {
    0% {
        font-size: $header-bracket-size;
    }
    100% {
        font-size: $header-bracket-size-full;
    }
}

@font-face {
    font-family: 'iosevka-thin-mono';
    src: url('/static/rendering/results/iosevka-selection.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

header.sta-header {
    height: calc(5vh + 96px);
    position: relative;
    transition: margin-top 0.3s ease-out 0.1s;
    font-family: "iosevka-thin-mono", serif;

    top: -100%;
    animation-name: header-intro;
    animation-duration: 0.4s;
    animation-delay: $header-intro-delay;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;

    @at-root body.sta-params-collapsed header {
        margin-top: -140px;
    }

    @include media-breakpoint-down(xs) {
        transition-duration: 0s;
        transition-delay: 0s;
        animation-delay: 0s;
        animation-duration: 0s;
        margin-top: -30px;
    }

    #header-text-canvas {
        text-align: center;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        height: 96px;

        @include media-breakpoint-down(xs) {
            display: none;
        }

        #header-text {
            font-weight: 600;
            font-size: 4em;
            @include text-shadow-on($deep-purple);

        }

        #header-subline {
            position: absolute;
            bottom: -5px;
            left: 0;
            right: -7px;
            font-weight: 600;
            letter-spacing: 6.6px;
            opacity: 0;
            animation: header-subline $subline-duration linear $subline-delay forwards;
            // on xs we dont do the type animation
            @include media-breakpoint-down(xs) {
                animation-delay: 2s;
            }

            // Bruh.. Safari10.0 works
            @include on_safari_13 {
                font-size: 14px;
            }
        }

        .header-bracket {
            position: absolute;
            left: -50px;
            top: 0;
            font-size: $header-bracket-size;
            @include text-shadow-on($deep-purple);
            font-weight: 600;
            animation: bracket-grow 0.3s ease-in-out $subline-delay - 0.08s forwards;
        }

        #header-bracket-right {
            right: -50px;
            left: unset;
        }
    }

    #header-logo-xs {
        display: none;
        width: 320px;
        max-width: 100vw;
        margin: 0 auto;

        @include media-breakpoint-down(xs) {
            display: block;
        }

        img {
            max-width: 100%;
        }
    }

    a {
        text-decoration: none;
        color: $body-color;
    }
}
