$collapsed-screen-height: 65px !default;
@keyframes boot-menu-items {
    from {
        right: 0;
    }

    to {
        right: -100%;
    }
}

// need a different animation-name (!) so the animation starts.
// otherwise the CSS engines dont recognize the new animation-name assignment as
// an intend to redo the animation.
@for $i from 1 through 2 {
    @keyframes teardown-screen-by-line-#{$i} {
        from {
            // clip all
            clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
        }

        50% {
            clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%);
        }

        to {
            clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)
        }
    }
}

@keyframes delayed-hide {
    0% {
        opacity: 1;
    }
    100% {
        opacitY: 0;
    }
}

@keyframes delayed-show {
    0% {
        opacity: 0; // TODO had a typo "opacitY", check if this caused unnecessary hacks
    }
    100% {
        opacity: 1;
    }
}

@for $i from 1 through 2 {
    @keyframes menu-icon-#{$i} {
        0%, 10% {
            background-position: -0 -0;
        }

        20% {
            background-position: -25px -0;
        }

        30% {
            background-position: -0 -25px;
        }

        40% {
            background-position: -25px -25px;
        }

        50% {
            background-position: -50px -0;
        }

        60% {
            background-position: -50px -25px;
        }

        70% {
            background-position: -0 -50px;
        }

        80% {
            background-position: -25px -50px;
        }

        90% {
            background-position: -50px -50px;
        }

        100% {
            background-position: -75px -0;
        }
    }
}

$line-flush-duration: 0.8s;
$line-fill-duration: 0.2s;
$line-build-duration: 0.2s;
$base-delay: $line-flush-duration * 0.5;
$step-factor: 0.2s;
$amount-menu-items: 4;

// user-agents (i hope all of them) put extra padding on <inputs>
$input-v-padding-adjustment: 3px;
/*
The goal of #menu-canvas is to overlay the params section, and tries
to align its menu items on the same vertical level as its underlying params <input>
*/
#menu-canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    visibility: hidden;

    .menu {
        opacity: 0;

        &.panel-head {
            // chevron-canvas takes 15% flex basis. To have menu's panel-head align properly, we act as if a chevron-canvas would be there.
            width: 85%;
        }
    }

    // $input-carret comes from "fancy-input.scss", but the machine should be able to work
    // without including that.
    @if not variable-exists(input-carret-left-offset) {
        $input-carret-left-offset: $default-padding * 3;
    }

    $menu-item-h-padding: calc(#{$panel-h-padding + $input-carret-left-offset} - 2px);
    // -2 px "reasons" spacer
    $menu-item-v-padding: calc(#{$panel-v-padding} + #{$input-v-padding-adjustment});

    .menu-item {
        display: block;
        padding: $menu-item-v-padding $menu-item-h-padding;
        position: relative;
        clip-path: polygon(0% 0, 0 0, 0 100%, 0% 100%); // clip-path has 0 width, so the item is not visible and its background shines through.
        transition: clip-path $line-fill-duration steps(6);
        color: $dark-greenscii;
        text-transform: uppercase;

        .sta-bubble {
            display: inline-block;
            width: 15px;
            height: 15px;
            background-color: $greenscii;
            border-radius: 50%;
        }

        &.sta-active {
            color: $greenscii;
        }

        &.menu-item-1 {
            padding-top: calc(#{$default-padding * 5} + #{$input-v-padding-adjustment});
        }

        &.menu-item-4 {
            // spacing due to work-exp-buttons being larger than input fields
            margin-top: 2px;
        }
    }

    .language-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

#menu-icon {
    width: 25px;
    height: 25px;
    background-image: url("/static/rendering/results/menu-icon-sheet.png");
    background-position: -0 -0;
    animation-duration: 0.3s;
    animation-timing-function: steps(1);
    animation-fill-mode: forwards;

    @at-root .sta-menu-open & {
        animation-name: menu-icon-1;
    }

    @at-root .sta-menu-closing & {
        animation-name: menu-icon-2;
        animation-direction: reverse;
        //animation-duration: 0.5s;
    }
}

/*
The #menu-builder overlays the params- and menu-section. Before both sections switch visibilities,
it animates itself over both sections, so they can perform their swap once the overlay reached its peak (overlays everything).
 */
#menu-builder {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 0;

    animation-duration: $line-flush-duration;
    animation-timing-function: steps(20);
    animation-fill-mode: forwards;

    // The overlay needs the same background as the params section. Animation moves the clipping window, so it
    // appears to build up stepwise
    @extend %screen-bg-radial;
}

#matching-machine-screen {
    &.sta-menu-open {
        // Hide params section after half the flush's duration (overlay complete).
        .panel:not(.menu):not(.panel-head),
        .panel-head .headline,
        .param-divider {
            animation: delayed-hide 0.01s forwards $line-flush-duration / 2;
        }

        #menu-canvas {
            visibility: visible;
        }

        // Everyting menu related gets visible per-se after overlay is complete.
        .menu {
            animation: delayed-show 0.01s forwards $line-flush-duration / 2;
        }

        .menu-item {
            clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%); // clip-path fully covers item, so it's visible.
        }

        @for $i from 1 through 5 {
            .menu-item-#{$i} {
                transition-delay: $line-flush-duration * 0.75 + (($i - 1) * $line-fill-duration);
            }
        }


        #menu-builder {
            animation-name: teardown-screen-by-line-1;
        }
    }

    &.sta-menu-closing {
        .panel:not(.menu):not(.panel-head),
        .panel-head .headline,
        .param-divider {
            opacity: 0; // required between closing intend and start of own animation
            animation: delayed-show 0.01s forwards $line-flush-duration / 2;
        }

        #menu-canvas {
            visibility: visible;
        }

        .menu {
            opacity: 1;
            animation: delayed-hide 0.01s forwards $line-flush-duration / 2;
        }

        // Need to get .menu-item's clip-path back to initial state before the item is not drawn anymore (due to menu-canvas being hidden).
        // If it's not drawn, the new initial state next time the menu opens will be the state where the transition was aborted (due parent hiding),
        // and not the initial state, we told it to have.
        .menu-item {
            clip-path: polygon(0% 0, 0 0, 0 100%, 0% 100%); // clip-path has 0 width, so the item is not visible and its background shines through.
            transition-delay: $line-flush-duration / 2;
            transition-duration: 0.01s;
        }

        #menu-builder {
            animation-name: teardown-screen-by-line-2;
        }
    }
}
