detail-view-tags-component {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex: 1 1 100%;
    margin-top: $default-margin;

    .expand-tags-btn {
        margin: $default-margin / 4 0; // same v-margin as .tag
        border: 3px solid $black-shade-3;
        border-top-color: $black-shade-5;
        border-left-color: $black-shade-5;
        color: $black-shade-6;
        padding: 0 5px;
    }

    .tags-detailed + .tags-detailed {
        margin-top: $default-margin / 2;
    }

    .tag-kind-label {
        color: $black-shade-5;
        text-transform: uppercase;
    }

    span.tag {
        margin: $default-margin / 4;
        margin-left: 0;


        &:not(.sta-matching) {
            border-color: $black-shade-3;
            color: $black-shade-6;
        }

        @include on_one_column_layout {
            font-size: $font-size-base * 0.85;
        }
    }

    // TODO mach button
    .expand-tag {
        cursor: pointer;
    }

    .subtle {
        color: $black-shade-5;
    }
}
