@font-face {
    font-family: "Klingon";
    src: url("/static/rendering/results/klingon-gt-sign.woff2") format("woff");
    font-weight: normal;
    font-style: normal;
}

body.sta-klingon {
    &, header {
        font-family: "Klingon";
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes spawn-flag {
    0%, 99% {
        filter: url(#klingon-flag-glitch);
    }

    100% {
        filter: none;
    }
}

#lang-bubble-skull {
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s;
    height: 43px;
    width: 50px;
    pointer-events: none; // allow click-through to flag

    @include media-breakpoint-down(sm) {
        height: 35px;
        width: 40px;
    }

    @at-root #menu-canvas.sta-klingon-flash #lang-bubble-skull {
        opacity: 1;
        animation: shake 0.5s 1;
        pointer-events: unset;
    }
}

switch-language-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > span {
        user-select: none;
        color: $body-color;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    #klingon-btn {
        opacity: 0;
        pointer-events: none;
    }


    .languages {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &.sta-klingon #klingon-btn {
            opacity: 1;
            animation: spawn-flag 0.5s 1 linear;
            pointer-events: unset;
        }

        $languages: (
                "de": "german_flag.png",
                "en": "english_flag.png",
                "tlh": "klingon_flag.png"
        );
        @each $name, $flag-img in $languages {
            &[sta-lang="#{$name}"] button[data-lang="#{$name}"] {
                @include active-check-button;
                filter: none;
            }

            button[data-lang="#{$name}"] {
                filter: saturate(0.2);
                background-image: url("/static/rendering/results/flags/#{$flag-img}");
                background-size: cover;
                height: 35px;
                width: 50px;

                @include media-breakpoint-down(sm) {
                    height: 28px;
                    width: 40px;
                }
            }
        }
    }
}
