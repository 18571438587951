.color-greenscii {
    color: $greenscii;
}

.color-jellycherry {
    color: $jelly-cherry;
}

.color-jellycherryjazzy {
    color: $jelly-cherry-jazzy;
}

.color-blackshade4 {
    color: $black-shade-5;
}

.color-deeppurple {
    color: $deep-purple;
}

.color-violetvelvet {
    color: $violet-velvet;
}

.color-blackshade6 {
    color: $black-shade-6;
}
