
profile-matching-bars-component {
    display: flex;
    height: 33px;
    flex: 1 1 auto;
    position: relative;
    justify-content: center;
    align-items: stretch;
    // to vertical align with "Profil" text
    padding: 9px 0;

    .bar {
        background-color: $earl-grey;
        width: 10px;
        height: 0;
        margin: 0 1px;
        display: inline-block;
        transition: height 0.2s ease-in-out;
        align-self: flex-end;
    }

    &.active .bar {
        background-color: $dark-greenscii;
    }
}
