$line-numbers-col-width: 50px !default;
$line-numbers-min-height: 70vh !default;
$line-numbers-line-height: 2em !default;

line-numbers-component {
    display: block;
    width: $line-numbers-col-width; // This is made up to fit 3 digits wide
    min-width: $line-numbers-col-width;
    max-width: $line-numbers-col-width;
    color: $deep-purple;
    border-right: 2px solid $deep-purple;
    text-align: right;
    position: relative;
    overflow: hidden;
    min-height: $line-numbers-min-height;

    span {
        padding: 0 $default-padding * 2;
        display: block;
        height: $line-numbers-line-height;
        max-height: $line-numbers-line-height;
    }

    @include media-breakpoint-down(xs) {
        display: none;
    }
}
