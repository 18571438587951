tag-search-component {
    display: block;

    .state-indicator {
        position: absolute;
        right: 15px;

        &.sta-loading {
            @include loading-icon;
        }
    }

    input.sta-no-results {
        border: 2px solid $orange-olafson;

        &::placeholder {
            color: transparentize($orange-olafson, 0.5);
        }
    }
}
