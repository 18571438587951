/**
 * Contains *resuable* components of the matching-machine.
 * Eg: Nothing related to user-params or the like. Just the shape and outlines, box-position and so on.
 */

.monitor-panel {
    opacity: 0;
    transition: opacity 0.1s;

    &#top-monitor-chasis {
        position: absolute;
        top: -50px;
        left: -43px;
        right: -79px;
        bottom: -20px;
        z-index: -1;

        background-image: url(/static/rendering/results/matching_machine_top.svg);
        background-size: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;

        @include on_safari {
            left: -42px;
            right: -78px;
        }

        @include on_firefox {
            left: -42px;
            right: -78px;
        }

        @include media-breakpoint-down(sm) {
            top: -39px;
            left: -36px;
            right: -67px;
        }

        @include media-breakpoint-down(xs) {
            background-image: url(/static/rendering/results/matching_machine_xs_top.svg);
            left: -22px;
            right: -22px;
            top: -23px;

            @include on_mobile_safari {
                left: -18px !important;
                right: -22px;
                width: 320px;
            }
        }
    }

    &#fill-monitor-chasis {
        position: absolute;
        top: 64px;
        left: -43px;
        right: -79px;
        bottom: 0;
        z-index: -1;
        background-image: url(/static/rendering/results/matching_machine_interim.svg);
        background-size: 100%;
        background-position: 0 0;
        background-repeat: repeat-y;

        @include on_safari {
            left: -42px;
            right: -78px;
        }

        @include on_firefox {
            left: -42px;
            right: -78px;
        }

        @include media-breakpoint-down(sm) {
            top: 62px;
            left: -36px;
            right: -67px;
        }

        @include media-breakpoint-down(xs) {
            background-image: url(/static/rendering/results/matching_machine_xs_interim.svg);
            top: 16px;
            left: -22px;
            right: -22px;

            @include on_mobile_safari {
                left: -18px !important;
                width: 320px;
            }
        }
    }

    &#bottom-monitor-chasis {
        position: absolute;
        top: 0;
        left: -43px;
        right: -79px;
        bottom: -58px;
        z-index: -1;

        background-image: url(/static/rendering/results/matching_machine_bottom_collapsed.svg);
        background-size: 100%;
        background-position: 0 100%;
        background-repeat: no-repeat;

        &.sta-extended {
            bottom: -58px;
            left: -43px;
            right: -79px;
            background-image: url(/static/rendering/results/matching_machine_bottom_extended.svg);
        }

        @at-root body.sta-params-collapsed & {
            top: 90%;
        }

        @include on_firefox {
            left: -42px;
            right: -78px;
        }

        @include media-breakpoint-down(sm) {
            left: -36px;
            right: -67px;
            bottom: -50px;

            &.sta-extended {
                bottom: -45px;
                left: -36px;
                right: -67px;
            }
        }

        @include media-breakpoint-down(xs) {
            background-image: url(/static/rendering/results/matching_machine_xs_bottom.svg) !important;

            &, &.sta-extended {
                left: -22px !important;
                right: -22px !important;
                bottom: -34px !important;

                @include on_mobile_safari {
                    left: -18px !important;
                    width: 322px;
                    transform: translateX(-1px);
                }
            }
        }
    }

    &#screen-border {
        position: absolute;
        top: -4px;
        left: -6px;
        right: -2px;
        bottom: -3px;
        background-color: $earl-grey;
        z-index: -1;
        border-radius: 5px;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    &#postit-left {
        position: absolute;
        top: -71px;
        left: -122px;
        width: 100px;
        height: 140px;
        z-index: -1;

        background-image: url(/static/rendering/results/postit_left.svg);
        background-size: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
    }

    &#postit-right {
        display: none;
        position: absolute;
        top: -69px;
        width: 111px;
        height: 140px;
        right: -122px;
        z-index: -1;

        background-image: url(/static/rendering/results/postit_right.svg);
        background-size: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
    }

    &.postit {
        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    @media (max-width: 1760px) and (min-width: 1440px) {
        &#postit-left {
            display: none;
        }
        &#postit-right {
            display: block;
        }
    }

    @media (max-width: 1420px) and (min-width: 1200px) {
        &#postit-left {
            display: none;
        }
        &#postit-right {
            display: block;
        }
    }

    @media (max-width: 1186px) and (min-width: 991px) {
        &#postit-left {
            display: none;
        }
        &#postit-right {
            display: block;
        }
    }
}

.monitor-led {
    @keyframes opacity-step {
        0% {
            opacity: 0.4;
        }

        100% {
            opacity: 1;
        }
    }

    position: absolute;
    bottom: -34px;
    z-index: -1;
    width: 20px;
    height: 20px;
    opacity: 0;
    animation-fill-mode: forwards;

    @include media-breakpoint-down(sm) {
        bottom: -30px;
        width: 15px;
        height: 15px;
    }
    @include media-breakpoint-down(xs) {
        bottom: -24px;
        height: 10px;
        width: 10px;
    }

    @at-root body.sta-is-matching .monitor-led {
        animation-name: opacity-step;
        animation-duration: 0.2s;
    }

    &.sta-active {
        opacity: 1;
    }

    &#led-1,
    &#led-2,
    &#led-3 {
        background-image: url(/static/rendering/results/led_on.svg);
        background-size: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
    }

    &#led-1 {
        right: 0;
        animation-delay: 0.13s;
        @include media-breakpoint-down(sm) {
            right: -2px;
        }
        @include media-breakpoint-down(xs) {
            right: 2px;

            @include on_mobile_safari {
                right: 10px;
            }
        }
    }

    &#led-2 {
        right: 30px;
        animation-delay: 0.16s;
        @include media-breakpoint-down(sm) {
            right: 22px;
        }
        @include media-breakpoint-down(xs) {
            right: 19px;

            @include on_mobile_safari {
                right: 26px;
            }
        }
    }

    &#led-3 {
        right: 61px;
        animation-delay: 0.19s;
        animation-duration: 0.1s;
        animation-iteration-count: infinite;

        @include media-breakpoint-down(sm) {
            right: 48px;
        }
        @include media-breakpoint-down(xs) {
            right: 36px;

            @include on_mobile_safari {
                right: 43px;
            }
        }
    }
}

#params-box-shadow {
    background-color: rgba(0, 0, 0, 0.9);
    width: 460px;
    height: 50px;
    position: fixed;
    bottom: 30px;
    left: 90px;
    transform: skew(-59deg, 0deg);
    border-radius: 50%;
    filter: blur(21px);
    z-index: -1;

    @at-root body.sta-params-collapsed & {
        background-color: rgba(0, 0, 0, 0.6);
    }

    @include media_breakpoint-down(xs) {
        display: none;
    }

    @include on_one_column_layout {
        left: 50%;
        transform: skew(-59deg, 0deg) translateX(-50%);
    }
}

.reflection {
    position: absolute;
    z-index: 6;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;

    @include media-breakpoint-down(xs) {
        display: none;
    }

    &#reflection-top {
        background-image: url("/static/rendering/results/reflection_top.png");
        background-position: top right;
        opacity: 0.15; // 0 when collapsed
        top: -20px;
        right: -20px;
    }

    &#reflection-bottom {
        background-image: url("/static/rendering/results/reflection_bottom.png");
        background-position: bottom left;
        opacity: 0.2;
        transition: opacity 2s ease-in 0.5s;
        bottom: -10px;
        left: -10px;
        top: unset;
        right: unset;
    }
}

#inner-shadow {
    position: absolute;
    top: -5px;
    bottom: -2px;
    left: -5px;
    right: -3px;
    z-index: 7;
    border-radius: 5px;
    box-shadow: inset -48px 50px 30px -22px rgba(0, 0, 0, 0.48);
    pointer-events: none;
}
