no-results-component {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: $line-height-result-list;

    padding-right: $default-padding * 4;

    .exit-code {
        color: $jelly-cherry-jazzy;
    }
}
