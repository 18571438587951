job-mailer-button-component {
    display: block;

    &.sta-prominence {
        bottom: 12px;
        left: 0;
        right: 0;
        z-index: 2;
        display: block;
        margin-left: -15px;
        margin-right: -15px;

        button {
            display: flex;
            justify-content: space-between;
            align-items: center;

            background-color: #1a084cd4;
            color: $greenscii;
            margin-left: 15px;
            padding: $default-padding 1.4rem; // on two-column, the envelops left side should be in line with the matching-bars
            letter-spacing: 0.15em;
            text-transform: uppercase;

            $_border-radius: 20px;
            border: 1px solid $greenscii;
            border-bottom: none;
            border-top-left-radius: $_border-radius;
            border-top-right-radius: $_border-radius;

            &:active, &:focus {
                outline: none;
                border-width: 2px;
            }

            img {
                max-width: 32px;
                height: 19px;
                margin-right: 15px;
                margin-bottom: 1px;
            }

            > span {
                display: none;
            }
        }

        @include on_two_column_layout {
            position: sticky;
        }

        @include on_one_column_layout {
            position: fixed;
            bottom: 0;
            display: flex;
            justify-content: center;


            button {
                margin: 0;
                padding-top: $medium-padding;
                padding-bottom: $medium-padding;
            }
        }
    }
}

