// Display a <input> element with a ">" sign infront of the actual text area.
//
// HTML Example:
//
//  <input class="fancy-input">
//  <span class="input-carret">&gt;</span>
//

$input-carret-left-offset: $default-padding * 3;

.input-carret {
    color: $greenscii;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: $input-carret-left-offset;
    font-weight: $font-weight-bold;
}

.fancy-input {
    background-color: $black-shade-1;
    border-color: transparent;
    color: $body-color;
    padding-left: $input-carret-left-offset + $default-padding * 4 !important;
    width: 100%;

    &::placeholder {
        color: #8F8F8F;
    }
}
