@mixin animation-lazor-vertical($lazor-width, $lazor-height) {
    @keyframes lazor-vertical {
        0% {
            height: 0;
            width: $lazor-width;
            top: 50%;
        }

        100% {
            height: $lazor-height;
            width: $lazor-width;
            top: calc(5vh + 96px);
        }
    }
}

@mixin animation-lazor-horizontal($lazor-width) {
    @keyframes lazor-horizontal {
        0% {
            height: 100%;
            width: $lazor-width - 8px;
        }

        70% {
            width: $lazor-width - 8px;
        }

        90% {
            width: 100%;
        }

        100% {
            height: 100%;
            width: 95%;
        }
    }
}

@mixin animation-lazor-horizontal-mobile($lazor-width) {
    @keyframes lazor-horizontal-mobile {
        0% {
            height: 100%;
            width: $lazor-width - 8px;
        }

        70% {
            width: $lazor-width - 8px;
        }

        90% {
            width: 100%;
        }

        100% {
            height: 100%;
            width: 100%;
        }
    }
}

@mixin animation-lazor-horizontal-glow {
    @keyframes lazor-horizontal-glow {
        0% {
            opacity: 0;
        }

        70% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }
}

@mixin animation-lazor-horizontal-mobile-glow {
    @keyframes lazor-horizontal-mobile-glow {
        0% {
            opacity: 1;
        }

        70% {
            opacity: 1;
        }
        99% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}

@mixin animation-lazor-close($lazor-width) {
    @keyframes lazor-close {
        0% {
            background-color: #000;
            height: 100%;
            width: 95%;
            top: 0;
        }

        10% {
            width: 100%;
        }

        30% {
            width: $lazor-width - 8px;
            height: 100%;
        }

        // other half
        70% {
            background-color: #fff;
            height: 100%;
            width: $lazor-width - 8px;
            top: 0;
        }

        100% {
            background-color: #fff;
            height: 0;
            width: $lazor-width - 8px;
            top: 50%;
            display: none;
        }
    }
}
