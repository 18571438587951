$page-container-xlg-full-width: true !default;
@import "../../../lib/style/itjobs-background";

@font-face {
    font-family: 'droid_sans_monoregular';
    src: url('/static/rendering/results/DroidSansMono-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: "droid_sans_monoregular", sans-serif;
}

.page-container {
    min-height: 100%;
    position: relative;

    @include media-breakpoint-down(xs) {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }

    @include media-breakpoint-down(lg) {
        background-position-y: 20vh;
        background-position-x: 100%;
    }

    @include media-breakpoint-down(md) {
        background-image: none;
    }

    @if $page-container-xlg-full-width {
        @include media-breakpoint-up(1600px) {
            max-width: calc(100vw - 30px);
            margin-left: auto;
            margin-right: auto;
        }
    }

}

.computers-table {
    right: -3vw;
    bottom: 0;
    position: fixed;
    /*    background-repeat: no-repeat;
        background-size: 50%;
        background-position: 104% 100%;*/
    pointer-events: none;
    z-index: -1;

    > svg {
        width: 50vw;
    }

    @include media-breakpoint-down(md) {
        display: none !important;
    }

    .sponsoring-element {
        opacity: 0;
    }

    &.sponsoring-active {
        .cobweb, .normal-balloons, .cup, .main-display-sad, .side-display-sad {
            opacity: 0;
        }

        .coffee-steam {
            animation: none !important;
            opacity: 0;
        }

        .sponsoring-element {
            opacity: 1;
        }
    }

    &.user-sponsored {
        #side-displays-sad-faces {
            opacity: 0;
        }

        .sponsoring-element.sponsored {
            opacity: 1;
        }

        .sponsoring-element.not-sponsored {
            opacity: 0;
        }
    }
}

.language-switch-hint {
    position: absolute;
    top: 10px;
    right: 95px;
    z-index: 21; //yikes.
    a {
        color: $purple-parfume;
    }

    @include media-breakpoint-down(xs) {
        display: none;
    }
}

// normal computers-table.
// background-image is set by directive.
#computers-table {
    @at-root body[sta-state="404"] & {
        display: none;
    }
    @at-root body[sta-state="410"] & {
        display: none;
    }
}

#computers-table-4xx, #computers-table-4xx-lg {
    display: none;
    @at-root body[sta-state="404"] & {
        display: block;
        .status-404 {
            display: block;
        }
        .status-410 {
            display: none;
        }
    }
    @at-root body[sta-state="410"] & {
        display: block;
        .status-410 {
            display: block;
        }
        .status-404 {
            display: none;
        }
    }
}

#main-page-row {
    @include media-breakpoint-down(xs) {
        margin: 0;
    }
}

#left-page-col {
    @include media-breakpoint-down(xs) {
        padding-left: 0;
        padding-right: 0;
    }
}

// To reduce pagespeed's cumulative layout shifts (CLS) metric,
// that is caused by angular booting up and populating the <ui-views> we have and thus
// moving <footer> around a whole lot, we go with a min-width for the <ui-view> container.
// Only mobile though, because the booting CLS is negligible on desktop.
// https://trello.com/c/J7udPne9
#page-views {
    @include media-breakpoint-down(xs) {
        min-height: 541px;
    }
}

#details-col {
    min-height: 100vh;
    position: relative;

    // Between one-col-layout and XS, we can save some h-space.
    // For XS its needed anyways.
    @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
    }
}
