view4xx-component {
    display: block;
    width: 100%;
    height: 100%;
}


// on screens >= MD, the image from index.hbs takes over the job.
#job-not-found {
    position: relative;
    text-align: center;
    color: #fff;
    display: none;
    height: 100%;

    @include media-breakpoint-down(md) {
        display: block;
    }
}

body[sta-state="404"], body[sta-state="410"] {
    @extend %mobile-detail-view-on-body;
}
