@import "animations-detail-view";

$lazor-width: 10px;
$lazor-height: 100vh !default;
@include animation-lazor-vertical($lazor-width, $lazor-height);
@include animation-lazor-horizontal($lazor-width);
@include animation-lazor-horizontal-mobile($lazor-width);
@include animation-lazor-horizontal-glow;
@include animation-lazor-horizontal-mobile-glow;
@include animation-lazor-close($lazor-width);

$lazor-vertical-grow-duration: 0.1s;
$lazor-horizontal-grow-duration: 0.2s;

$lazor-turn-on-glow-duration: 0.1s;
$lazor-turn-on-glow-duration-xs: 0.2s;

$lazor-closing-duration: 0.2s;
$lazor-content-appear-delay: 0.3s;
$lazor-content-appear-duration: 0.1s;

#thunder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    z-index: 10;

    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    pointer-events: none;

    margin: 0 auto;
    background-color: $black-shade-1;
    // 179deg so that there are no visible brightness steps rendered
    background: linear-gradient(179deg, $black-shade-1 40%, $black-shade-2);

    animation-duration: $lazor-vertical-grow-duration;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    // Pseudo-Element contains the lazor glow
    &:after {
        animation-duration: $lazor-turn-on-glow-duration;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;

        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0 0 3px 5px #fff, 0 0 30px 15px #0095ff;
        opacity: 0;
        pointer-events: none;
    }

    // set when $ctrl.Result != null. starts vertical grow
    &.sta-grow-vertical:not(.sta-grow-instant) {
        display: flex;
        pointer-events: unset;
        animation-name: lazor-vertical;
    }

    // Set by detailViewComponent 250ms after $ctrl.Result was set.
    // So 250ms after the vertical built animaiton was started, we run the horizontal grow one.
    &.sta-grow-horizontal:not(.sta-grow-instant) {
        animation-name: lazor-horizontal;
        animation-duration: $lazor-horizontal-grow-duration;
        animation-timing-function: ease-in;

        @include media-breakpoint-down(xs) {
            animation-name: lazor-horizontal-mobile;
        }

        &:after {
            animation-name: lazor-horizontal-glow;

            @include media-breakpoint-down(xs) {
                animation-name: lazor-horizontal-mobile-glow;
                animation-duration: $lazor-turn-on-glow-duration-xs;
            }
        }


        &:not(.sta-closing) .visible-after-lazor {
            opacity: 1 !important;
            transition: opacity $lazor-content-appear-duration linear $lazor-content-appear-delay;

            // old Edge does not support "revert", so we overwrite them with fallback values
            //noinspection CssOverwrittenProperties
            max-width: 100%;
            overflow: auto;

            //noinspection CssOverwrittenProperties
            max-width: revert;
            overflow: revert;


            // reverting "overflow" of details-scroll does not yield the desired
            // effects. advert flows over the thunder-bottom.
            // overflow is set as part of the thunder animation to prevent window
            // overflows and thus horizontal scrollbars.
            &#details-scroll {
                overflow: auto;
                overflow-y: scroll;
            }
        }
    }

    &.sta-closing:not(.sta-grow-instant) {
        animation-name: lazor-close;
        animation-duration: $lazor-closing-duration;
        animation-timing-function: ease-in;

        // When closing the lazor, the glow should vanish immediately
        &:after {
            opacity: 0 !important; // overwrites animation "forwards"
        }
    }

    &.sta-grow-instant {
        display: flex;
        top: 0;

        height: 100%;
        width: 95%;
        pointer-events: unset;

        @include media-breakpoint-down(xs) {
            width: 100%;
        }

        &:after {
            opacity: 1;
        }

        .visible-after-lazor {
            opacity: 1 !important;
            transition: none;
            max-width: 100%;
            overflow: auto;

            &#details-scroll {
                overflow: auto;
                overflow-y: scroll;
            }
        }
    }
}
