@keyframes unix-loading {
    0% {
        content: "\\";
    }

    25% {
        content: "|";
    }

    50% {
        content: "/";
    }

    75% {
        content: "-";
    }
}

@mixin loading-icon {
    &:after {
        content: "\\";
        display: inline-block;
        font-family: "droid_sans_monoregular", monospace, serif;
        font-weight: $font-weight-bold;
        height: 100%;
        animation-name: unix-loading;
        animation-duration: 1.0s;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
        animation-timing-function: steps(4);
    }
}
