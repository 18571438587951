results-page-divider-component {
    display: block;
    position: relative;

    height: $line-height-result-list;
    width: calc(100% + #{$default-padding * 4});
    margin-left: $default-padding * -4;
    margin-right: $default-padding * -4;
    margin-top: $line-height-result-list * 2;

    border-top: 2px solid $deep-purple;

    text-align: center;
}
