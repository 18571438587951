.footer-intro {
    margin-top: $default-margin * 23;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    hr {
        background-color: $black-shade-5;
        margin: $default-margin / 3 0;
    }

    $max-base-width: 300;
    $first-line-percent: 80;
    $second-line-percent: 50;
    $third-line-percent: 20;

    hr:nth-child(1) {
        width: $first-line-percent * 1%;
        max-width: ($max-base-width / 100 * $first-line-percent) * 1px;
    }

    hr:nth-child(2) {
        width: $second-line-percent * 1%;
        max-width: ($max-base-width / 100 * $second-line-percent) * 1px;
    }

    hr:nth-child(3) {
        width: $third-line-percent * 1%;
        max-width: ($max-base-width / 100 * $third-line-percent) * 1px;
    }
}

.footer-canvas {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: $default-margin * 2 0;

    // This is actually only for safari, but doesnt
    // hurt for other browsers
    padding-bottom: $default-padding * 4;

    .footer-item {
        font-weight: 600;
        color: $body-color;
        font-size: $font-size-smaller;
        margin: 0 $default-margin;

        &:hover {
            text-decoration: underline;
        }
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;

        .footer-item {
            margin: $default-margin / 2;
        }
    }
}
