@keyframes unix-loading {
    0% {
        content: "\\";
    }

    25% {
        content: "|";
    }

    50% {
        content: "/";
    }

    75% {
        content: "-";
    }
}

results-loading-component {
    display: block;
    height: $line-height-result-list;
    color: $black-shade-5;

    &:before {
        content: "\\";
        display: inline-block;
        animation-name: unix-loading;
        animation-duration: 1.0s;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
        animation-timing-function: steps(4);
    }
}
