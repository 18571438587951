@import "../../../lib/style/simple_html_resets";
@import "components/detail-view-tags.scss";
@import "detailview/thunder";

#details-portal {
    display: block; // just to make sure a <ui-view> works here too
    width: 100%;
    height: 100vh;
}

detail-view-component,
.detail-view-dummy {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    .visible-after-lazor {
        // For a built, but not displayed, lazor, the detail actions reach outside the page and
        // cause an overflow.
        // This eg. happens when detail view is closed.
        max-width: 0;
        overflow: hidden;
    }

    #detail-header {
        opacity: 0;
        padding: $default-padding * 2 $default-margin * 2.5;
        padding-bottom: $default-margin * 2;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: $default-margin * 2;
        position: relative; // for display-switch button
        z-index: 1; // required, so the box-shadow overlapy on the advert
        flex: 0 0 auto;
    }

    #company-logo-canvas {

        img {
            padding: $default-padding * 2 $default-padding * 4;
            background-color: #fff;
            border-radius: 5px;
        }

        @media (max-width: 400px) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;

            img {
                height: 44px;
                width: 94px;
            }

            @include on_mobile_safari {
                right: 10px;
                margin-bottom: $default-margin;
            }
        }
    }

    #detail-actions {
        display: flex;
    }

    #detail-view-display-switch {
        text-align: right;
        padding: $default-padding 0;
        color: $black-shade-5;
        font-size: $font-size-base * 0.75;
        flex: 1 1 100%;

        @media (max-width: 400px) {
            padding-top: $default-padding * 4;
            padding-bottom: 0;
        }
    }

    #apply-btn {
        @include enter-button;

        &.sta-disabled {
            filter: saturate(0);
            cursor: not-allowed;

            &:hover, &:focus, &:active {
                box-shadow: none;
            }
        }
    }

    #close-detail-view-button {
        @include delete-button;
    }

    #detail-company-name-region {
        background-color: $black-shade-3;
        padding: $default-padding * 2 $default-padding * 4;
        position: relative;
        border-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;


        .detail-region {
            color: $black-shade-6;
            font-size: $font-size-sm;
        }

        @include on_one_column_layout {
            margin-left: -4px;
            margin-right: -4px;
        }
    }

    #details-scroll {
        flex: 1 1 auto;
        margin: $default-margin $default-margin * 2.5;
        margin-top: 0;

        overflow-y: scroll;
        overscroll-behavior: none;
        opacity: 0;

        @include purple-scroll(#333, #555);

        /**
         * Following handles the scroll-shadows
         */
        &:before, &:after {
            position: sticky;
            display: block;
            content: "";
            width: 100%;
            box-shadow: 0 0 20px 20px $black-shade-1;
            opacity: 1;
            transition: opacity 0.5s;
            z-index: 1;
        }

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
            box-shadow: none;
        }

        &:not(.sta-scrolled):before {
            opacity: 0;
        }
    }

    #advert-canvas {
        @include reset_simple_html();

        > *:first-child {
            margin-top: 30px !important;
        }

        > *:last-child {
            margin-bottom: 30px !important;
        }
    }

    #iframe-canvas-detail-view {
        // same as #advert-canvas
        margin: $default-margin $default-margin * 2.5;
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

@import "layouts/two-column-layout";
@import "layouts/one-column-layout";
@import "layouts/xl-adjustments";
