matching-score-bars-component {
    $skill-bars-canvas-width: 40px;
    $skill-bars-canvas-left-offset: $default-padding * 5;
    $progress-bar-width: 9px;

    $skill-bar-color: darken($greenscii, 20%);
    $exp-bar-color: darken($greenscii, 27.5%);
    $distance-bar-color: darken($greenscii, 35%);

    .score-bars-canvas {
        position: absolute;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        left: $skill-bars-canvas-left-offset;
        bottom: $line-height-result-list / 4;
        height: $line-height-result-list * 2.5;
        width: $skill-bars-canvas-width;
        padding: $default-padding;
        padding-bottom: 0;
        // we need the z-index here, otherwise the hover is not firing
        // when hovering over the .progress-bar children
        z-index: 2;

        @include media-breakpoint-down(xs) {
            bottom: $default-padding * 2; // .result has this bottom padding on XS
            height: $line-height-result-list-xs * 2.5;
        }
    }

    .progress-bar {
        background-color: $black-shade-3;
        width: $progress-bar-width;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .progress-value {
        background-color: $distance-bar-color;
        height: 0; // set via inline styles
        width: $progress-bar-width;

        &.progress-value-skills {
            background-color: $skill-bar-color;

            &.no-matching-skills {
                // We need to darken this by 10% because it has more mass than the
                // "// No skill match" comment text, which uses the same color
                background-color: darken($black-shade-4, 10);
            }
        }

        &.progress-value-experience {
            background-color: $exp-bar-color;
        }
    }

    .score-bars-explanation {
        display: none;
        position: absolute;
        color: $black-shade-6;

        // padding * 14 == .informations padding
        left: $result-padding-left + ($default-padding * 14);
        bottom: 0;
    }

    @media (hover: none) {
        &.sta-show {
            .score-bars-explanation {
                display: block;
            }

            & ~ .result-body .informations {
                opacity: 0;
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            .score-bars-explanation {
                display: block;
            }

            & ~ .result-body .informations {
                opacity: 0;
            }
        }
    }
}
