detail-view-call-to-action-component {
    button {
        display: block;
        font-weight: $font-weight-bold !important;

        & + button {
            margin-top: $default-margin / 2;
        }

        i {
            @include loading-icon;
        }
    }
}
