@mixin on_one_column_layout {
    @include media-breakpoint-down(md) {
        @content;
    }
}

@mixin on_two_column_layout {
    @include media-breakpoint-up(md) {
        @content;
    }
}
