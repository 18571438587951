$fa-font-path: "/static/rendering/results";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

// TODO there should already be proper columns, or extend the $grid-map. also maybe look into "container-fluid"
$generate-extra-columns: true;
$font-size-base: 1rem;
@import "../../../lib/style/bootstrap4-base";
@import "../../../lib/style/components/buttons";
@import "../../../lib/style/color-helpers";

@import "../../../lib/Modal/vanilla-modals.scss";
@import "../../../lib/Feedback/style/user-feedback.scss";
@import "../../../lib/Footer/footer.scss";
@import "../../../lib/Translations/style/switch-language-component.scss";
@import "../../../lib/Header/header.scss";
@import "../../../lib/Helper/colored-scrollbar-mixin";
@import "../../../lib/Helper/sta-bg-img.scss";
@import "../../../lib/style/tron";
@import "../../../lib/style/sponsoring-event";
@import "../../../lib/style/components/fancy-input";

@import "layouts/column-mixins";
@import "tag-selected-glow-animation";

$line-height-result-list: 2em;
$line-height-result-list-xs: 1.5em;
@import "main-page-layout";
@import "detail-view-page";
@import "tag";
@import "matchingmachine/matching-machine";
@import "components/matching-params-component";
@import "components/results-list";
@import "components/results-loading-component";
@import "components/no-results-component";
@import "components/matching-score-bar";
@import "components/contact-modal";
@import "components/job-mailer-button-component";
@import "components/job-mailer-modal";
@import "components/profile-matching-bars";
@import "components/view-4xx-component";
@import "components/detail-view-call-to-action-component";
@import "components/switch-language-component";

%mobile-detail-view-on-body {
    @include on_one_column_layout {
        overflow: auto; // overwrite .sta-details-open which usually hide overflowing content

        #left-page-col footer {
            display: none;
        }

        #details-col {
            position: relative;
            margin-top: -80px;

            footer {
                display: block;
            }
        }
    }
}

@import "components/city-landing-content-component";
@import "components/city-landing-contentless-component";
@import "components/job-title-landing-contentless-component";

@import "components/achievement-list-component";
@import "achievements/coin";
@import "achievements/steam-machine";
@import "achievements/sql-injection";
@import "achievements/tamagotchi";
@import "achievements/flash";
@import "achievements/bug";
@import "aoc";

#details-col footer {
    display: none;
}

.footer-intro {
    margin-top: $default-margin;
}

.valorant-mobile {
    display: none;
}
@media (max-width: 991.98px) {
    body:not(.sta-details-open, .sta-params-collapsed) .valorant-mobile {
        display: block;
        position: relative;
        margin-top: 60px;
        text-align: center;
        svg {
            width: 90vw;
            max-width: 300px;
        }
    }
}