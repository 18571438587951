@keyframes fade-in-left {
    0% {
        transform: translateX(-30px);
        opacity: 0.3;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-out-right {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(30px);
        opacity: 0.3;
    }
}

$modal-width: 80vw;
$modal-width-mobile: 95vw;
$modal-max-width: 1000px;
$modal-headline-color: $body-color;
$modal-headline-background: $body-bg;

.sta-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    background-color: rgba(0, 0, 0, 0.7);

    display: flex;
    justify-content: center;
    align-items: center;

    .sta-modal-wrapper {
        width: $modal-width;
        max-width: $modal-max-width;
        border-radius: 3px; //smooth the things

        background-color: $body-bg;
        box-shadow: 0 -1px 6px 0px #8a8a8a;

        animation-name: fade-in-left;
        animation-duration: 0.1s;
        animation-fill-mode: none;

        // those two make the modal popup scrollable, if the screen size is too low.
        max-height: 100%;
        overflow: auto;

        &.leaving {
            animation-name: fade-out-right;
        }

        // Only apply centered text by default for basic translation modals.
        &.basic-translation-modal {
            .modal-body {
                text-align: center;
            }
        }

        @include media-breakpoint-down(sm) {
            width: $modal-width-mobile;
        }
    }

    .modal-headline {
        // the headline element itself, and also any headlines nested in it, like a <h2> inside .modal-headline.
        // They tend to have extra color+bg definitions
        &, & * {
            background-color: $modal-headline-background;
            color: $modal-headline-color;
        }

        border-radius: 3px;
        padding: $medium-padding * 2 0;
        text-align: center;
        font-size: $h3-font-size;
        font-weight: $font-weight-bold;

        @include media-breakpoint-down(sm) {
            font-size: $font-size-lg;
        }
    }

    .modal-body {
        padding: $default-padding * 3 $default-padding * 2;
    }

    .modal-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $medium-padding;

        &.reverse {
            flex-direction: row-reverse;
        }
    }
}

body.sta-modal-open {
    overflow: hidden;
}
