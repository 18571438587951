$top-sheet-width: 4480px;
$bottom-sheet-width: 4480px;
$fps: 11;
$num-sprites: 7;
$top-sprite-width: 630px;
$top-sprite-height: 279px;
$bottom-sprite-width: 630px;
$bottom-sprite-height: 257px;
$animation-duration: $num-sprites / $fps;

@keyframes steam-top {
    1% {
        opacity: 0.5;
    }
    99% {
        opacity: 0.5;
        background-position: $top-sheet-width * -1 0;
    }
    100% {
        max-width: 0;
    }
}

@keyframes steam-bottom {
    1% {
        opacity: 0.5;
    }
    99% {
        opacity: 0.5;
        background-position: $bottom-sheet-width * -1 0;
    }
    100% {
        max-width: 0;
    }
}

$sprite-path: "/static/rendering/results/mib_sprites.png";
$sprites: -1053px -706px, -1053px -532px, -1053px -358px, -1053px -184px, -1053px -10px, -904px -706px, -904px -532px, -904px -358px, -904px -184px, -904px -10px, -755px -706px, -606px -706px, -457px -706px, -308px -706px, -159px -706px, -10px -706px, -755px -532px, -755px -358px, -755px -184px, -755px -10px, -606px -532px, -457px -532px, -308px -532px, -159px -532px, -10px -532px, -606px -358px, -606px -184px, -606px -10px, -457px -358px, -308px -358px, -159px -358px, -10px -358px, -457px -184px, -457px -10px, -308px -184px, -159px -184px, -10px -184px, -308px -10px, -159px -10px, -10px -10px;
$step: 100 / length($sprites);

@keyframes mib-smoke-sprites {
    @for $i from 1 through length($sprites) {
        #{($i - 1) * $step}% {
            background-position: nth($sprites, $i);
        }
    }

}

@each $i in 1, 2 {
    @keyframes unlock-display-#{$i} {
        0% {
            transform: translateY(0) scale(1);
        }

        40% {
            transform: translateY(10px) scale(0.97);
        }

        70% {
            transform: translateY(10px) scale(0.97);
        }

        73% {
            transform: translateY(0px) scale(0.97);
        }

        100% {
            transform: translateY(700px) scale(0.97);
        }
    }
}

$slit-offset: 3%;
@each $i in 1, 2 {
    @keyframes build-mib-image-#{$i} {
        from {
            // clip all
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0, 0 100%, 0 100%);
        }

        40%, 73% {
            clip-path: polygon(0 0, 100% 0, 100% $slit-offset, $slit-offset $slit-offset, $slit-offset 100%, 0 100%);
        }

        to {
            //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, $slit-offset 100%, $slit-offset 100%, 0 100%);
        }
    }
}

.matching-machine {
    $door-open-delay: 0.8s;
    $ruckeln-duration: 0.1s;
    $open-delay: 0.4s;
    $door-moving-duration: 1.4s;

    // TODO does nothing, apparently
    // after makes the growing shadow on the "door"
    #matching-machine-screen:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        right: 0;
        box-shadow: inset -20px 70px 30px -10px #000000e0;
        width: 100%;
        opacity: 0;
        pointer-events: none;
    }

    &.sta-door-open {
        @keyframes ruckeln {
            0% {
                opacity: 1;
                transform: translate(-2.5px, -2.5px);
            }

            45% {
                transform: translate(2.5px, 2.5px);
            }

            90%, 100% {
                opacity: 1;
                transform: translate(0, 0);
            }
        }

        animation: ruckeln $ruckeln-duration steps(3);
        opacity: 1; // to fix the animation overwrite of "params-opacity"

        #matching-machine-screen {
            overflow: hidden;

            // TODO does nothing, I think
            &:after {
                opacity: 1;
                transition: opacity 0.5s linear $open-delay + 0.3s;
            }

            .panel, .param-divider, .panel-seperator {
                animation: unlock-display-1 $door-moving-duration steps(5) $open-delay + 0.2s forwards;
                transform-origin: right;
                z-index: 10;
                position: relative;
            }
        }

        // There are no inset shadows, onces the door is open.
        #inner-shadow {
            opacity: 0;
            transition: opacity 0s (0.4 * $door-moving-duration);
        }

        &:not(.sta-door-closing) #screen-border {
            background-color: $black-shade-1;
            transition: background-color 0.1s linear $ruckeln-duration - 0.05s;
        }

        .machine-steam {
            max-width: unset;
        }

        #steam-top {
            animation-name: steam-top;
            animation-fill-mode: forwards;
            animation-delay: $open-delay - 0.1s;
        }

        #steam-bottom {
            animation-name: steam-bottom;
            animation-fill-mode: forwards;
            animation-delay: $open-delay - 0.1s;
        }

        // once the bright room reveals, the "active glow" is always present. not only on hover
        .screen-glow-area {
            opacity: 1;
        }

        #mib-post-office {
            z-index: 6;
            animation: build-mib-image-1 $door-moving-duration steps(5) $open-delay + 0.2s forwards;
        }

        #mib-smoke {
            animation: mib-smoke-sprites 4.2s steps(1) ($door-moving-duration + $open-delay + 0.2s) forwards;
        }
    }

    &.sta-door-closing {
        #matching-machine-screen {
            overflow: hidden;

            .panel, .param-divider, .panel-seperator {
                animation: unlock-display-2 $door-moving-duration steps(5) 0s reverse;
            }
        }

        #mib-post-office {
            z-index: 6;
            animation: build-mib-image-2 $door-moving-duration steps(5) 0s reverse;
        }

        .screen-glow-area {
            opacity: 0;
        }

        // inset shadow slowly appears again
        #inner-shadow {
            opacity: 1;
            transition: opacity 0s (0.4 * $door-moving-duration);
        }
    }

    .machine-steam {
        position: absolute;
        opacity: 0;
        animation-timing-function: steps($num-sprites);
        animation-fill-mode: backwards;
        animation-duration: #{$num-sprites / $fps}s;
        max-width: 0;
    }

    #steam-top {
        left: -135px;
        top: -165px;
        width: $top-sprite-width;
        height: $top-sprite-height;
    }

    #steam-bottom {
        left: -140px;
        bottom: -150px;
        width: $bottom-sprite-width;
        height: $bottom-sprite-height;
    }

    #knebel-hebel {
        position: absolute;
        top: -84px;
        z-index: -2;
        width: 20px;
        cursor: pointer;
        transform: rotate(-45deg);
        transition: transform 0.2s;
        transform-origin: bottom;

        @include media-breakpoint-down(sm) {
            top: -70px;
        }

        @include media-breakpoint-down(xs) {
            top: -55px;
        }

        &.sta-on {
            transform: rotate(45deg);
        }
    }

    // background-image gets loaded on pageload, but we only need them, once the lever got pushed.
    &.sta-load-mib-img {
        #mib-post-office {
            background-image: url("/static/rendering/results/mib-post-office.png");
        }

        #mib-smoke {
            background-image: url($sprite-path);
        }

        #steam-top {
            background-image: url(/static/rendering/results/steam_top.png);
        }

        #steam-bottom {
            background-image: url(/static/rendering/results/steam_bottom.png);
        }
    }

    #mib-post-office {
        position: absolute;
        top: 0;
        left: -2px; // compensate for the now-missing screen-border
        width: 101%; // compensate for the now-missing screen-border
        height: 100%;
        z-index: 0;
        background-position: left;
        background-size: cover;
        // clip all
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    #mib-smoke {
        width: 129px;
        height: 154px;
        position: absolute;
        top: 54px;
        left: 136px;
        z-index: 20;
        background-position: nth($sprites, 1);
        transform: scale(0.95); // sprites dont align *perfectly*

        // On firefox though, if scaled down, the sprites jump around v.v
        @include on_firefox {
            transform: scale(1);
        }
    }
}
