@include on_two_column_layout {
    #page-views {
        min-height: 100vh;
    }

    #details-col {
        position: fixed;
        z-index: 20;
        top: 0;
        bottom: 0;
        right: 0;
    }
}
